import { Provider } from '@lazr/openapi-client'
import { OrderInfoCustomsDetail, SearchRfqCustomsDetail } from '../../model'
import { isMailingService } from '@lazr/utilities'
import { TransportType } from '@lazr/enums'
import {
    CanadaPostCustomsDetail,
    CanadaPostCustomsDetailOptional,
    CanparCustomsDetail,
    DhlCustomsDetail,
    FedexCustomsDetail,
    FedexCustomsDetailOptional,
    P44CustomsDetail,
    PurolatorCustomsDetail,
    PurolatorCustomsDetailOptional,
    RoseRocketCustomsDetail,
    UpsCustomsDetail,
} from '../../model/ProviderSpecifics'

export const createCustomsDetailObject = (provider: Provider | undefined,
    customsDetail: SearchRfqCustomsDetail | OrderInfoCustomsDetail,
    transportType: TransportType | undefined,
    commercialInvoiceDocumentIndicator: boolean,
) :
CanadaPostCustomsDetail |
PurolatorCustomsDetail |
FedexCustomsDetail |
CanparCustomsDetail |
UpsCustomsDetail |
DhlCustomsDetail |
RoseRocketCustomsDetail |
P44CustomsDetail | undefined => {
    const isMailing = isMailingService(transportType)
    const isEnvelope = transportType === TransportType.ENVELOPE

    switch (provider) {
        case Provider.CANADA_POST: {
            if (!isEnvelope) {
                if (!customsDetail.unitWeight ||
                    !customsDetail.weightUnit ||
                    !customsDetail.description ||
                    !customsDetail.unitValue ||
                    !customsDetail.quantity
                ) {
                    throw new Error(('Required customs detail fields missing.'))
                }

                if (commercialInvoiceDocumentIndicator){
                    if (!customsDetail.countryOfManufacture?.code) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }
            }

            return {
                canadaPostSpecifics: {
                    ParcelPakEnvelope: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight,
                        weightUnit: customsDetail.weightUnit,
                        description: customsDetail.description,
                        unitValue: customsDetail.unitValue,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                }
            } as CanadaPostCustomsDetail
        }

        case Provider.PUROLATOR: {
            if (!isEnvelope) {
                if (
                    !customsDetail.productCode ||
                    !customsDetail.description ||
                    !customsDetail.unitValue ||
                    !customsDetail.quantity ||
                    !customsDetail.countryOfManufacture?.code ||
                    !customsDetail.unitWeight ||
                    !customsDetail.weightUnit ||
                    !customsDetail.harmonizedCode
                ) {
                    throw new Error(('Required customs detail fields missing.'))
                }
            }

            return {
                purolatorSpecifics: {
                    ParcelPakEnvelope: {
                        productCode: customsDetail.productCode,
                        productIndicatorIsUsmca: customsDetail.productIndicatorIsUsmca ?? false,
                        productIndicatorFda: customsDetail.productIndicatorFda ?? false,
                        productIndicatorTextile: customsDetail.productIndicatorTextile ?? false,
                        documentIndicatorUsmca: customsDetail.documentIndicatorUsmca ?? false,
                        documentIndicatorFda: customsDetail.documentIndicatorFda ?? false,
                        documentIndicatorFcc: customsDetail.documentIndicatorFcc ?? false,
                        senderIsProducerIndicator: customsDetail.senderIsProducerIndicator ?? false,
                        textileManufacturer: customsDetail.textileManufacturer || null,
                        description: customsDetail.description,
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code,
                        unitValue: customsDetail.unitValue,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            } as PurolatorCustomsDetail
        }

        case Provider.FEDEX: {
            if (isMailing) {
                if (!isEnvelope) {
                    if (!customsDetail.description ||
                        !customsDetail.unitValue ||
                        !customsDetail.quantity ||
                        !customsDetail.unitWeight ||
                        !customsDetail.weightUnit ||
                        !customsDetail.countryOfManufacture?.code
                    ) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }

                return {
                    fedexSpecifics: {
                        ParcelPakEnvelope: {
                            productIndicatorUsmcaType: customsDetail?.productIndicatorUsmcaType || null,
                            description: customsDetail?.description,
                            harmonizedCode: customsDetail?.harmonizedCode || null,
                            countryOfManufacture: customsDetail?.countryOfManufacture?.code,
                            unitValue: customsDetail?.unitValue,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail?.quantity,
                            unitWeight: customsDetail.unitWeight,
                            weightUnit: customsDetail.weightUnit,
                            squareMeters: customsDetail.squareMeters || null,
                        }
                    },
                } as FedexCustomsDetail
            } else {
                if (!customsDetail.description ||
                    !customsDetail.countryOfManufacture?.code ||
                    !customsDetail.unitValue ||
                    !customsDetail.quantity ||
                    !customsDetail.unitWeight ||
                    !customsDetail.weightUnit
                ) {
                    throw new Error(('Required customs detail fields missing.'))
                }

                if (commercialInvoiceDocumentIndicator) {
                    if (!customsDetail.harmonizedCode) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }

                return {
                    fedexSpecifics: {
                        LTL: {
                            productIndicatorUsmcaType: customsDetail?.productIndicatorUsmcaType || null,
                            description: customsDetail?.description,
                            harmonizedCode: customsDetail?.harmonizedCode || null,
                            countryOfManufacture: customsDetail?.countryOfManufacture?.code,
                            unitValue: customsDetail?.unitValue,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail?.quantity,
                            unitWeight: customsDetail.unitWeight,
                            weightUnit: customsDetail.weightUnit,
                            pieces: customsDetail.pieces,
                            squareMeters: customsDetail.squareMeters || null,
                        }
                    }
                } as FedexCustomsDetail
            }
        }
        case Provider.CANPAR: {
            if (!isEnvelope) {
                if (commercialInvoiceDocumentIndicator){
                    if (!customsDetail.unitWeight ||
                        !customsDetail.weightUnit ||
                        !customsDetail.description ||
                        !customsDetail.unitValue ||
                        !customsDetail.quantity ||
                        !customsDetail.countryOfManufacture?.code
                    ) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }
            }

            return {
                canparSpecifics: {
                    ParcelPakEnvelope: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        description: customsDetail.description || null,
                        unitValue: customsDetail.unitValue || null,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            }
        }

        case Provider.UPS:
            return {
                upsSpecifics: {
                    ParcelPakEnvelope: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        description: customsDetail.description || null,
                        unitValue: customsDetail.unitValue || null,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            }

        case Provider.DHL: {
            if (!isEnvelope) {
                if (commercialInvoiceDocumentIndicator){
                    if (!customsDetail.unitWeight ||
                        !customsDetail.weightUnit ||
                        !customsDetail.description ||
                        !customsDetail.unitValue ||
                        !customsDetail.quantity ||
                        !customsDetail.countryOfManufacture?.code
                    ) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }
            }

            return {
                dhlSpecifics: {
                    ParcelPakEnvelope: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        description: customsDetail.description || null,
                        unitValue: customsDetail.unitValue || null,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            }
        }

        case Provider.ROSE_ROCKET: {
            if (!isEnvelope) {
                if (commercialInvoiceDocumentIndicator){
                    if (!customsDetail.unitWeight ||
                        !customsDetail.weightUnit ||
                        !customsDetail.description ||
                        !customsDetail.unitValue ||
                        !customsDetail.quantity ||
                        !customsDetail.countryOfManufacture?.code
                    ) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }
            }

            return {
                roseRocketSpecifics: {
                    LTL: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        description: customsDetail.description || null,
                        unitValue: customsDetail.unitValue || null,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            }
        }

        case Provider.PROJECT_44: {
            if (!isEnvelope) {
                if (commercialInvoiceDocumentIndicator){
                    if (!customsDetail.unitWeight ||
                        !customsDetail.weightUnit ||
                        !customsDetail.description ||
                        !customsDetail.unitValue ||
                        !customsDetail.quantity ||
                        !customsDetail.countryOfManufacture?.code
                    ) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }
            }

            return {
                p44Specifics: {
                    LTL: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        description: customsDetail.description || null,
                        unitValue: customsDetail.unitValue || null,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            }
        }

        default:
            return undefined
    }
}

export const createCustomsDetailObjectOptional = (provider: Provider | undefined,
    customsDetail: SearchRfqCustomsDetail | OrderInfoCustomsDetail,
    transportType: TransportType | undefined,
    commercialInvoiceDocumentIndicator: boolean,
) :
CanadaPostCustomsDetailOptional |
PurolatorCustomsDetailOptional |
FedexCustomsDetailOptional |
CanparCustomsDetail |
UpsCustomsDetail |
DhlCustomsDetail |
RoseRocketCustomsDetail |
P44CustomsDetail | undefined => {
    const isMailing = isMailingService(transportType)
    const isEnvelope = transportType === TransportType.ENVELOPE

    switch (provider) {
        case Provider.CANADA_POST: {
            return {
                canadaPostSpecifics: {
                    ParcelPakEnvelope: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail?.unitWeight ?? undefined,
                        weightUnit: customsDetail.weightUnit ?? undefined,
                        description: customsDetail.description ?? undefined,
                        unitValue: customsDetail.unitValue ?? undefined,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity ?? undefined,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                }
            } as CanadaPostCustomsDetailOptional
        }

        case Provider.PUROLATOR: {
            return {
                purolatorSpecifics: {
                    ParcelPakEnvelope: {
                        productCode: customsDetail?.productCode ?? undefined,
                        productIndicatorIsUsmca: customsDetail.productIndicatorIsUsmca ?? false,
                        productIndicatorFda: customsDetail.productIndicatorFda ?? false,
                        productIndicatorTextile: customsDetail.productIndicatorTextile ?? false,
                        documentIndicatorUsmca: customsDetail.documentIndicatorUsmca ?? false,
                        documentIndicatorFda: customsDetail.documentIndicatorFda ?? false,
                        documentIndicatorFcc: customsDetail.documentIndicatorFcc ?? false,
                        senderIsProducerIndicator: customsDetail.senderIsProducerIndicator ?? false,
                        textileManufacturer: customsDetail.textileManufacturer || null,
                        description: customsDetail.description ?? undefined,
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code,
                        unitValue: customsDetail.unitValue ?? undefined,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity ?? undefined,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            } as PurolatorCustomsDetailOptional
        }

        case Provider.FEDEX: {
            if (isMailing) {
                return {
                    fedexSpecifics: {
                        ParcelPakEnvelope: {
                            productIndicatorUsmcaType: customsDetail?.productIndicatorUsmcaType || null,
                            description: customsDetail?.description ?? undefined,
                            harmonizedCode: customsDetail?.harmonizedCode || null,
                            countryOfManufacture: customsDetail?.countryOfManufacture?.code ?? undefined,
                            unitValue: customsDetail?.unitValue ?? undefined,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail?.quantity ?? undefined,
                            unitWeight: customsDetail.unitWeight ?? undefined,
                            weightUnit: customsDetail.weightUnit ?? undefined,
                            squareMeters: customsDetail.squareMeters || null,
                        }
                    }
                } as FedexCustomsDetailOptional
            } else {
                return {
                    fedexSpecifics: {
                        LTL: {
                            productIndicatorUsmcaType: customsDetail?.productIndicatorUsmcaType || null,
                            description: customsDetail?.description ?? undefined,
                            harmonizedCode: customsDetail?.harmonizedCode || null,
                            countryOfManufacture: customsDetail?.countryOfManufacture?.code ?? undefined,
                            unitValue: customsDetail?.unitValue ?? undefined,
                            unitCurrency: customsDetail?.unitCurrency || null,
                            quantity: customsDetail?.quantity ?? undefined,
                            unitWeight: customsDetail.unitWeight ?? undefined,
                            weightUnit: customsDetail.weightUnit ?? undefined,
                            pieces: customsDetail.pieces ?? undefined,
                            squareMeters: customsDetail.squareMeters || null,
                        }
                    }
                } as FedexCustomsDetailOptional
            }
        }

        case Provider.CANPAR: {
            if (!isEnvelope) {
                if (commercialInvoiceDocumentIndicator){
                    if (!customsDetail.unitWeight ||
                        !customsDetail.weightUnit ||
                        !customsDetail.description ||
                        !customsDetail.unitValue ||
                        !customsDetail.quantity ||
                        !customsDetail.countryOfManufacture?.code
                    ) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }
            }

            return {
                canparSpecifics: {
                    ParcelPakEnvelope: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        description: customsDetail.description || null,
                        unitValue: customsDetail.unitValue || null,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            }
        }

        case Provider.UPS: {
            return {
                upsSpecifics: {
                    ParcelPakEnvelope: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        description: customsDetail.description || null,
                        unitValue: customsDetail.unitValue || null,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            }
        }

        case Provider.DHL: {
            if (!isEnvelope) {
                if (commercialInvoiceDocumentIndicator){
                    if (!customsDetail.unitWeight ||
                        !customsDetail.weightUnit ||
                        !customsDetail.description ||
                        !customsDetail.unitValue ||
                        !customsDetail.quantity ||
                        !customsDetail.countryOfManufacture?.code
                    ) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }
            }

            return {
                dhlSpecifics: {
                    ParcelPakEnvelope: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        description: customsDetail.description || null,
                        unitValue: customsDetail.unitValue || null,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            }
        }

        case Provider.ROSE_ROCKET: {
            if (!isEnvelope) {
                if (commercialInvoiceDocumentIndicator){
                    if (!customsDetail.unitWeight ||
                        !customsDetail.weightUnit ||
                        !customsDetail.description ||
                        !customsDetail.unitValue ||
                        !customsDetail.quantity ||
                        !customsDetail.countryOfManufacture?.code
                    ) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }
            }

            return {
                roseRocketSpecifics: {
                    LTL: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        description: customsDetail.description || null,
                        unitValue: customsDetail.unitValue || null,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            }
        }

        case Provider.PROJECT_44: {
            if (!isEnvelope) {
                if (commercialInvoiceDocumentIndicator){
                    if (!customsDetail.unitWeight ||
                        !customsDetail.weightUnit ||
                        !customsDetail.description ||
                        !customsDetail.unitValue ||
                        !customsDetail.quantity ||
                        !customsDetail.countryOfManufacture?.code
                    ) {
                        throw new Error(('Required customs detail fields missing.'))
                    }
                }
            }

            return {
                p44Specifics: {
                    LTL: {
                        harmonizedCode: customsDetail.harmonizedCode || null,
                        countryOfManufacture: customsDetail.countryOfManufacture?.code || null,
                        unitWeight: customsDetail.unitWeight || null,
                        weightUnit: customsDetail.weightUnit || null,
                        description: customsDetail.description || null,
                        unitValue: customsDetail.unitValue || null,
                        unitCurrency: customsDetail.unitCurrency || null,
                        quantity: customsDetail.quantity || null,
                        squareMeters: customsDetail.squareMeters || null,
                    },
                },
            }
        }

        default:
            return undefined
    }
}
