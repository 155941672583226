import convert from 'convert-units'
import moment from 'moment'
import React, { useEffect, useState, useCallback } from 'react'

import {
    Checkbox,
    Collapse,
    FormControlLabel,
    Hidden,
    InputAdornment,
    type Theme,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'

import {
    setAccessorialList as reduxSetAccessorialList,
    setAddressAccessorials as reduxSetAddressAccessorials,
    setCommodityType as reduxSetCommodityType,
    setCoverageCommodityTypeList as reduxSetCoverageCommodityTypeList,
    setDestinationAddressBookAddress as reduxSetDestinationAddressBookAddress,
    setEtdEnabled as reduxSetEtdEnabled,
    setIsBookedWithCoverage as reduxSetIsBookedWithCoverage,
    setIsCoverageExclusionsAccepted as reduxSetIsCoverageExclusionsAccepted,
    setMarketPlaceLastCompletedStep as reduxSetMarketPlaceLastCompletedStep,
    setMarketPlaceStep as reduxSetMarketPlaceStep,
    setOrderInformation as reduxSetOrderInformation,
    setOriginAddressBookAddress as reduxSetOriginAddressBookAddress,
    setPackageTypeList as reduxSetPackageTypeList,
    setPromptModalLeave as reduxSetPromptModalLeave,
    setRfqInformation as reduxSetRfqInformation,
    setSchedulePickup as reduxSetSchedulePickup,
    setSelectedQuote as reduxSetSelectedQuote,
    setServiceAccessorialList as reduxSetServiceAccessorialList,
    setShipmentCoverageValue as reduxSetShipmentCoverageValue,
    setHandlingUnitAccessorialList,
} from '../../../redux/actions/MarketPlaceActions'
import reduxSetSnackbar from '../../../redux/actions/SnackbarActions'
import {
    AddressValidation,
    ArrowIcon,
    ArrowIconContainer,
    BackButton,
    Card,
    CardContent,
    CardHeader,
    DefaultButton,
    Divider,
    Grid,
    IconLogo,
    LeftSideGrid,
    ModalTypography,
    ProgressSummaryInformation,
    ResetOrderButton,
    ResetOrderLabel,
    RightSideGrid,
    SaveAsDraftButton,
    ScrollBar,
    StepperGrid,
    StickyContainer,
    StyledIcon,
    TextField,
    TransitDaysTooltipIcon,
    WarningButton,
} from './ProgressSummary.styled'

import { logger } from '@/app/logger'
import type {
    Accessorial,
    Address,
    AddressAccessorial,
    Carrier,
    Country,
    PackageType,
    SaveableAddress,
    Search,
    SearchRfqAccessorial,
    SearchRfqHandlingUnit,
    SearchRfqHandlingUnitAccessorial,
    SearchRfqQuote,
} from '@/app/model'
import {
    AccessorialApiService,
    AddressApiService,
    CoverageCommodityTypeApiService,
    OrderApiService,
    PackageTypeApiService,
    SearchApiService,
} from '@/app/service/ApiService'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Country as CountryEnum,
    CoverageCommodityType,
    Currency,
    Provider,
    UserRole,

} from '@lazr/enums'
import {
    AccessorialLocationType,
    AccessorialType,
    AddressType,
    CollectAccountType,
    OrganizationStatus,
    PaymentTypeOption,
    TransportType,
    WeightUnit,
    DimensionUnit
} from '@lazr/openapi-client'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Modal, {
    BACK_ICON,
    CANCEL_ICON,
    DELETE_CONFIRM_ICON,
    NEXT_ICON,
    NO_ICON,
    OK_ICON,
    SETTINGS_ICON,
    SPINNER_ICON,
    RedStar,
    Props as ModalProps
} from '../../../components/Modal'
import { type CompleteOrderInformation, MarketPlaceStep } from '../../../definitions/MarketPlace'
import {
    ALLOWED_PUBLIC_MARKETPLACE_ROUTES,
    HANDLING_EQUIPMENT_TYPE,
    HANDLING_TRANSPORT_TYPE,
    MARKETPLACE_ROUTES,
    RegisterModalAction,
    UNAPPROVED_ORGANIZATION_MESSAGE,
} from '../../../lib/constants'
import {
    isAddressComplete,
    shouldForceCustomQuoteForAccessorials,
    shouldForceCustomQuoteForBlockAndBrace,
    shouldForceCustomQuoteForCargo,
    shouldForceCustomQuoteForEquipmentType,
    shouldForceCustomQuoteForLiftgateMaxHeight,
    shouldForceCustomQuoteForTransportType,
} from '../../../lib/helpers'
import { EST_TRANSIT_TOOLTIP_TEXT } from '../../../lib/tooltipTexts'
import {
    getDefaultAddressList as reduxGetDefaultAddressList,
    getDefaultUserCurrency as reduxGetDefaultUserCurrency,
    getUser as reduxGetUser,
    getUserOrganization as reduxGetUserOrganization,
} from '../../../redux/selectors'
import {
    getCollect as reduxGetCollect,
    getCompleteOrderInformation as reduxGetCompleteOrderInformation,
    getIsBookedWithCoverage as reduxGetIsBookedWithCoverage,
    getIsCoverageExclusionsAccepted as reduxGetIsCoverageExclusionsAccepted,
    getMarketPlace as reduxGetMarketPlace,
    getOrder as reduxGetOrder,
    getPaymentType as reduxGetPaymentType,
    getProgressSummary as reduxGetProgressSummary,
    getPromptModalLeave as reduxGetPromptModalLeave,
    getSelectedLane as reduxGetSelectedLane,
    getSelectedValidatedDestinationAddress as reduxGetSelectedValidatedDestinationAddress,
    getSelectedValidatedOriginAddress as reduxGetSelectedValidatedOriginAddress,
} from '../../../redux/selectors/MarketPlaceSelectors'
import useFormValidation, {
    completeOrderStepValidator,
    shipmentInformationStepValidator,
} from '../../marketplace/hooks/useFormValidator'
import type { ProgressSummaryInformationData } from './ProgressSummaryInformation'
import ProgressSummaryStepper from './ProgressSummaryStepper'
import RegisterModal from './RegisterModal'
import SignInModal from './SignInModal'
import {
    getBrowserTimezone,
} from './helpers'

import {
    addDefaultAccessorials,
    checkIsEtdAlwaysEnabled,
    formatTransitTime,
    isCaUsPrViLane,
    isInternationalOrder,
    isMailingService,
    round,
    sleep,
    getPaperCommInvFee,
    getWrongAddressSurcharge,
} from '@lazr/utilities'
import { getCurrencyRates as reduxGetCurrencyRates } from '@/app/ui/redux/selectors/CurrencyRatesSelectors'
import type { ValidatedAddress } from '../../../definitions/ValidatedAddress'

import { useMarketplaceNavigator } from '../hooks/useMarketplaceNavigator'
import { useQuery } from '../hooks/useQuery'

import {
    canparCompleteOrderStepValidator,
} from '@/app/ui/pages/marketplace/components/Canpar/CanparCustoms/CanparCompleteOrderStepValidator'
import { dhlCompleteOrderStepValidator } from '@/app/ui/pages/marketplace/components/Dhl/DhlCustoms/DhlCompleteOrderStepValidator'
import { upsCompleteOrderStepValidator } from '@/app/ui/pages/marketplace/components/Ups/UpsCustoms/UpsCompleteOrderStepValidator'
import {
    createProviderSpecificObject,
    getProviderSpecificUnsupportedEtdDocumentList,
} from '@/app/ui/pages/marketplace/components/helpers'
import { isNumber, omit } from 'lodash'
import { Prompt } from 'react-router'
import type { Store } from '../../../definitions/Store'
import { setNewPickupDate } from '../../../redux/actions/NewPickupDateActions'
import { addressBookRoute, homeRoute } from '../../../routes'
import RescheduledPickupModal from '../../components/RescheduledPickupModal'
import { OLD_UI_UNSUPPORTED_ACCESSORIALS } from '../../helpers'
import { canadaPostCompleteOrderStepValidator } from './CanadaPost/CanadaPostCustoms/CanadaPostCompleteOrderStepValidator'
import { fedexFreightCompleteOrderStepValidator } from './Fedex/FedexFreightCustoms/FedexFreightCompleteOrderStepValidator'
import { fedexMailCompleteOrderStepValidator } from './Fedex/FedexMailCustoms/FedexMailCompleteOrderStepValidator'
import { p44CompleteOrderStepValidator } from './P44/P44Customs/P44CompleteOrderStepValidator'
import { purolatorCompleteOrderStepValidator } from './Purolator/PurolatorCustoms/PurolatorCompleteOrderStepValidator'
import { roseRocketCompleteOrderStepValidator } from './RoseRocket/RoseRocketCustoms/RoseRocketCompleteOrderStepValidator'

import {
    CanadaPostSpecifics,
    FedexSpecifics,
    PurolatorSpecifics,
    CanparSpecifics,
    UpsSpecifics,
    DhlSpecifics,
    RoseRocketSpecifics,
    P44Specifics,
    GlsCanadaSpecifics,
 } from "@/app/model/ProviderSpecifics";

interface CollectAccount {
    accountNumber: string
    accountType: CollectAccountType
    carrier: Carrier
}

const getCargoInformation = (cargo: Partial<SearchRfqHandlingUnit>[], packageTypes: PackageType[]): string => {
    const formattedCargoInformation: string[] = []

    cargo.forEach((handlingUnit: Partial<SearchRfqHandlingUnit>) => {
        if (!handlingUnit.quantity || !handlingUnit.packageType) {
            return
        }
        const packageTypeLabel = packageTypes.find((packageType) => packageType.code === handlingUnit.packageType)
        if (packageTypeLabel) {
            let result
            if (handlingUnit.quantity !== 1) {
                result = `${handlingUnit.quantity} ${packageTypeLabel.pluralName}`
            } else {
                result = `${handlingUnit.quantity} ${packageTypeLabel.singularName}`
            }

            formattedCargoInformation.push(result)
        }
    })

    return formattedCargoInformation.join(', ')
}

const getAddressInformation = (city?: string | null, state?: string | null, postalCode?: string | null): string => {
    if (!city || !postalCode) {
        return ''
    }
    if (!state) {
        return `${city}, ${postalCode}`
    }

    return `${city}, ${state} ${postalCode}`
}

const addServiceLevelAccessorials = (
    serviceLevelAccessorials: SearchRfqAccessorial[] = [],
    originAccessorials: SearchRfqAccessorial[] = [],
    selectedResult: SearchRfqQuote | null,
): SearchRfqAccessorial[] => {
    if (!selectedResult?.serviceLevelCode || selectedResult.serviceLevelCode === 'STD') {
        return originAccessorials
    }
    const serviceAccessorial = serviceLevelAccessorials.find((serviceLevelAccessorial) =>
        serviceLevelAccessorial.accessorial.code === selectedResult.serviceLevelCode)

    if (serviceAccessorial) {
        originAccessorials.push({ accessorial: serviceAccessorial.accessorial, isRemovable: true, id: '' })
    } else {
        logger.debug(`addServiceLevelAccessorials: service level code: ${selectedResult.serviceLevelCode} not found`)
    }

    return originAccessorials
}
const ProgressSummary: React.FunctionComponent<Props> = () => {
    const user = useSelector(reduxGetUser)
    if (!user) {
        logger.warn('User is not defined')
    }
    const cadToUsdRate = useSelector(reduxGetCurrencyRates)[Currency.CAD][Currency.USD]
    const order = useSelector(reduxGetOrder)
    const collect = useSelector(reduxGetCollect)
    const paymentType = useSelector(reduxGetPaymentType)
    const selectedLane = useSelector(reduxGetSelectedLane)
    const providerSpecificCompleteOrderStepValidator =
        getProviderSpecificCompleteOrderStepValidator(order.rfq?.selectedQuote?.provider, order.rfq?.transportType)
    const [ validateShipmentInformationStep, isValidatingShipmentInformationStep, submitShipmentInformation ] =
        useFormValidation(shipmentInformationStepValidator)
    const [ validateCompleteOrderStep, isValidatingCompleteOrderStep ] = useFormValidation(providerSpecificCompleteOrderStepValidator)

    const dispatch = useDispatch()
    const history = useHistory()
    const theme: Theme = useTheme()
    const query = useQuery()
    const { resetMarketplace, cleanupMarketplace, resetMarketplaceRoute } = useMarketplaceNavigator()

    const isXs = useMediaQuery(theme.breakpoints.only('xs'))

    const [ isCollapsed, setIsCollapsed ] = useState<boolean>(true)

    const [ isStartingSearch, setStartingSearch ] = useState<boolean>(false)

    const [ isProcessingCustomQuote, setProcessingCustomQuote ] = useState<boolean>(false)

    const [ isBooking, setBooking ] = useState<boolean>(false)

    const [ isSubmitting, setSubmitting ] = useState<boolean>(false)
    const [ isChangedStep, setChangingStep ] = useState<boolean>(false)
    const [ isSubmittingDraft, setSubmittingDraft ] = useState<boolean>(false)

    const [ isAddressValidationModalOpen, setAddressValidationModalOpen ] = useState<boolean>(false)

    const [ carrierSuggestedAddresses, setCarrierSuggestedAddresses ] = useState<{
        originAddresses: ValidatedAddress[] | null
        destinationAddresses: ValidatedAddress[] | null
    } | null>(null)

    const [ isSameDayPickupDialogOpen, setIsSameDayPickupDialogOpen ] = useState<boolean>(false)

    const [ isConfirmResetOpened, setIsConfirmResetOpened ] = useState<boolean>(false)

    const [ isConfirmCoverageOpened, setIsConfirmCoverageOpened ] = useState<boolean>(false)

    const [ nextButtonIconProp, setNextButtonIconProp ] = useState<IconProp>(NEXT_ICON)
    const [ backButtonIconProp, setBackButtonIconProp ] = useState<IconProp>(BACK_ICON)

    const [ showExpiredModal, setShowExpiredModal ] = useState(false)
    const [ showBillingAddressModal, setShowBillingAddressModal ] = useState(false)
    const [ showRegisterModal, setShowRegisterModal ] = useState(false)
    const [ showSignInModal, setShowSignInModal ] = useState(false)
    const [ showCustomsDocumentsDisclaimerDialog, setShowCustomsDocumentsDisclaimerDialog ] = useState(false)
    const [ isSaveAsDraft, setIsSaveAsDraft ] = useState<boolean>(false)

    const [ modalLeaveDisplayed, setModalLeaveDisplayed ] = useState(false)
    const [ locationPathName, setLocationPathName ] = useState<string>('')
    const [ openPastDateOrderModal, setOpenPastDateOrderModal ] = useState<boolean>(false)
    const [ submitPastDateOrder, setSubmitPastDateOrder ] = useState<((callback: () => void) => void) | null>(null)
    const [ registerModalAction, setRegisterModalAction ] = useState(RegisterModalAction.REGISTER)

    const [ isSchedulePickupOpened, setIsSchedulePickupOpened ] = useState(false)

    const [ isConfirmSavingAddressToAddressBookOpened, setIsConfirmSavingAddressToAddressBookOpened ] = useState(false)
    const [ isSavingAddressAnimationStarted, setIsSavingAddressAnimationStarted ] = useState(false)
    const [ isSavingAddressButtonsDisabled, setIsSavingAddressButtonsDisabled ] = useState(false)
    const [ isSavingAddressConfirmationShown, setIsSavingAddressConfirmationShown ] = useState(false)
    const [ addressBookAddressesToSave, setAddressBookAddressesToSave ] = useState<{
        title: string
        message: string
        address: SaveableAddress | null
        type: AccessorialLocationType
    }[]>([])

    const [ showEtdModal, setShowEtdModal ] = useState(false)
    const etdModalProps = {
        carrierName: order.rfq?.selectedQuote?.carrierDisplayName,
        documentList: getProviderSpecificUnsupportedEtdDocumentList(order),
        supportDeletion: order.rfq?.selectedQuote?.quoteCarrier?.quoteCarrierService?.supportEtdDeletion,
    }
    const {
        lastCompletedStep,
        currentStep,
        isQuoteFetchingComplete,
        serviceAccessorialList,
        handlingUnitAccessorialList,
        destinationAccessorialList,
        originAccessorialList,
        packageTypeList,
        coverageCommodityTypeList,
    } = useSelector(reduxGetProgressSummary)

    const marketplace = useSelector(reduxGetMarketPlace)
    const internationalOrder = isInternationalOrder(order.rfq?.originCountry?.code, order.rfq?.destinationCountry?.code)

    const provider = order.rfq?.selectedQuote?.provider
    const transportType = order.rfq?.transportType


    const isEtdAlwaysEnabled = provider ? checkIsEtdAlwaysEnabled(provider) : false
    const providerSupportsEtd = order.rfq?.selectedQuote?.quoteCarrier?.quoteCarrierService?.supportEtd

    const promptModalLeave = useSelector(reduxGetPromptModalLeave)
    const defaultAddressList = useSelector(reduxGetDefaultAddressList)
    const userOrganization = useSelector(reduxGetUserOrganization)

    const originAccessorials = order?.rfq?.accessorials?.filter((accessorial) =>
        accessorial.accessorial.locationType === AccessorialLocationType.ORIGIN ||
        accessorial.accessorial.type === AccessorialType.SERVICE) ?? []

    const destinationAccessorials = order?.rfq?.accessorials?.filter((accessorial) =>
        accessorial.accessorial.locationType === AccessorialLocationType.DESTINATION) ?? []

    const isBookedWithCoverage = useSelector(reduxGetIsBookedWithCoverage)

    const commodityType = order.rfq?.coverageCommodityType

    const isCoverageExclusionsAccepted = useSelector(reduxGetIsCoverageExclusionsAccepted)

    const shipmentCoverageValue = order.rfq?.shipmentCoverageValue

    const originAddress = useSelector((store: Store) => reduxGetCompleteOrderInformation(store, AccessorialLocationType.ORIGIN))
    const destinationAddress = useSelector((store: Store) => reduxGetCompleteOrderInformation(store, AccessorialLocationType.DESTINATION))

    const selectedValidatedOriginAddress = useSelector(reduxGetSelectedValidatedOriginAddress)
    const selectedValidatedDestinationAddress = useSelector(reduxGetSelectedValidatedDestinationAddress)

    const defaultUserCurrency: Currency = useSelector(reduxGetDefaultUserCurrency)

    const providerSupportSchedulePickup = provider && transportType && isMailingService(transportType)
    const toggle = (): void => {
        setIsCollapsed(!isCollapsed)
    }

    useEffect(() => {
        const forceSignIn = query.get('forceSignin')
        if (forceSignIn === '1') {
            if (!user) {
                setShowSignInModal(true)
            } else {
                setShowSignInModal(false)
            }
        }
    }, [ user ])

    useEffect(() => {
        void (async (): Promise<void> => {
            if (coverageCommodityTypeList.length !== 0) {
                return
            }
            try {
                dispatch(reduxSetCoverageCommodityTypeList(await CoverageCommodityTypeApiService.list()))
            } catch (error: any) {
                logger.error(error)
            }
        })()
    }, [])

    useEffect(() => {
        void (async (): Promise<void> => {
            if (packageTypeList.length !== 0) {
                return
            }
            try {
                dispatch(reduxSetPackageTypeList(await PackageTypeApiService.list()))
            } catch (error: any) {
                logger.error(error)
            }
        })()
    }, [])

    useEffect(() => {
        void (async (): Promise<void> => {
            if (originAccessorialList.length !== 0 && destinationAccessorialList.length && handlingUnitAccessorialList.length) {
                return
            }
            let fetchedAccessorialList: Accessorial[] = []
            try {
                const transportType = order.rfq?.transportType
                if (transportType) {
                    switch (transportType) {
                        case TransportType.LTL:
                        case TransportType.FTL:
                        case TransportType.OTHER:
                            fetchedAccessorialList = await AccessorialApiService.list({
                                transportTypes:
                                    [ TransportType.LTL, TransportType.FTL, TransportType.OTHER ],
                            })
                            break
                        case TransportType.PAK:
                        case TransportType.PARCEL:
                        case TransportType.ENVELOPE:
                            fetchedAccessorialList = await AccessorialApiService.list({
                                transportTypes:
                                    [ TransportType.PAK, TransportType.PARCEL, TransportType.ENVELOPE ],
                            })
                            break
                    }
                } else {
                    fetchedAccessorialList = await AccessorialApiService.list()
                }
            } catch (error: any) {
                logger.error(error)

                return
            }

            const fetchedHandlingUnitAccessorialList: SearchRfqHandlingUnitAccessorial[] = fetchedAccessorialList
                .filter((accessorial) => accessorial.type === AccessorialType.COMMODITY)
                .filter((acc) => !OLD_UI_UNSUPPORTED_ACCESSORIALS.includes(acc.code))
                .map((accessorial) =>
                    ({
                        id: '',
                        accessorial,
                        isRemovable: true,
                    }))

            const fetchedServiceAccessorialList: SearchRfqAccessorial[] = fetchedAccessorialList
                .filter((accessorial) => accessorial.type === AccessorialType.SERVICE)
                .map((accessorial) =>
                    ({
                        id: '',
                        accessorial,
                        isRemovable: true,
                    }))

            const fetchedOriginAccessorialList: SearchRfqAccessorial[] = fetchedAccessorialList
                .filter((accessorial) => accessorial.locationType === AccessorialLocationType.ORIGIN)
                .map((accessorial) =>
                    ({
                        id: '',
                        accessorial,
                        isRemovable: true,
                    }))
                .concat(fetchedServiceAccessorialList)

            const fetchedDestinationAccessorialList: SearchRfqAccessorial[] = fetchedAccessorialList
                .filter((accessorial) => accessorial.locationType === AccessorialLocationType.DESTINATION)
                .filter((acc) => !OLD_UI_UNSUPPORTED_ACCESSORIALS.includes(acc.code))
                .map((accessorial) =>
                    ({
                        id: '',
                        accessorial,
                        isRemovable: true,
                    }))
            dispatch(setHandlingUnitAccessorialList(fetchedHandlingUnitAccessorialList))
            dispatch(reduxSetServiceAccessorialList(fetchedServiceAccessorialList))
            dispatch(reduxSetAccessorialList(fetchedOriginAccessorialList, fetchedDestinationAccessorialList))
        })()
    }, [])

    useEffect(() => {
        dispatch(reduxSetPromptModalLeave(currentStep === MarketPlaceStep.CONFIRMATION))
    }, [ currentStep === MarketPlaceStep.CONFIRMATION ])

    const onbeforeunload = (e: BeforeUnloadEvent) => {
        e.preventDefault()
        e.returnValue = ''
    }

    // Add/Remove handlers
    // To prevent multiple registration of that front-end event
    const addListeners = () => window.addEventListener('beforeunload', onbeforeunload)
    const removeListeners = () => window.removeEventListener('beforeunload', onbeforeunload)

    useEffect(() => {
        if (!isSubmitting && !isSubmittingDraft && currentStep === MarketPlaceStep.CONFIRMATION) {
            addListeners()
        } else {
            removeListeners()
        }

        return removeListeners
    }, [ isSubmitting, !isSubmittingDraft, currentStep === MarketPlaceStep.CONFIRMATION ])

    const hasDefaultBillingAddress = !user || (defaultAddressList && defaultAddressList.billingAddressList.length > 0)
    const isOrganizationAdmin = user?.permissions.organizationRole.role === UserRole.ADMIN || !!user?.getImpersonatingInfo().isAdmin

    let orderId = order?.id ?? localStorage.getItem('marketPlaceOrderId')
    const isMailing = isMailingService(order.rfq?.transportType)

    const showCustomQuote = shouldForceCustomQuoteForTransportType(order.rfq?.transportType)
        || shouldForceCustomQuoteForEquipmentType(order.rfq?.equipmentType)
        || shouldForceCustomQuoteForLiftgateMaxHeight(order.rfq ?? {})
        || (shouldForceCustomQuoteForCargo(order.rfq?.handlingUnits?.map((hu) => ({
                        quantity: hu.quantity || 0,
                        length: hu.length || 0,
                        lengthUnit: hu.lengthUnit || DimensionUnit.IN,
                        width:hu.width || 0,
                        widthUnit:hu.widthUnit || DimensionUnit.IN,
                        height: hu.height || 0,
                        heightUnit:hu.heightUnit || DimensionUnit.IN,
                        weight: hu.weight || 0,
                        weightUnit: hu.weightUnit || WeightUnit.LB,
                        packageType: hu.packageType,
                    }))
            ?? [] ,
            userOrganization?.name,
            transportType) && !isMailing)
        || shouldForceCustomQuoteForAccessorials(
            order.rfq?.accessorials ?? [],
            order.rfq?.handlingUnits?.reduce((joinedAccessorials: SearchRfqHandlingUnitAccessorial[], handlingUnit) =>
                joinedAccessorials.concat(handlingUnit.accessorials ?? []), []) ?? [],
        )
        || shouldForceCustomQuoteForBlockAndBrace(order.rfq?.accessorials ?? [], order.rfq?.handlingUnits ?? [])

    const isCustomQuoteDisabled = (currentStep === MarketPlaceStep.SHIPMENT_INFORMATION && !order.rfq?.transportType) ||
        (currentStep === MarketPlaceStep.MARKETPLACE && !isQuoteFetchingComplete) ||
        isProcessingCustomQuote

    const isLazrItButtonDisabled = (!!order.rfq?.transportType && !isMailingService(order.rfq.transportType) &&
            !order.rfq?.equipmentType)
        || isStartingSearch
        || lastCompletedStep >= MarketPlaceStep.SHIPMENT_INFORMATION
        || isValidatingShipmentInformationStep
        || isProcessingCustomQuote

    const totalNumberOfSubmittedOrders = (isMailing ? userOrganization?.totalNumberOfSubmittedParcelOrders :
        userOrganization?.totalNumberOfSubmittedFreightOrders) ?? 1

    const maxOrders = (isMailing ? userOrganization?.maxParcelOrders :
        userOrganization?.maxFreightOrders) ?? null

    const reachedOrganizationMaxOrderLimit = maxOrders !== null && totalNumberOfSubmittedOrders >= maxOrders

    const selectedQuote = order.rfq?.selectedQuote

    const isPickupAvailable = // TODO: When redoing the schedule pickup form, we should revist this condition. This is a temporary solution for Canada Post
        !(selectedQuote?.provider === Provider.UPS &&
                selectedQuote.carrierCode === 'UPS' &&
                selectedQuote.shippingMode?.code === 'GROUND' &&
                order?.rfq?.originCountry?.code === CountryEnum.CA)

    const reachedOrganizationMaxCreditLimit =
        userOrganization?.creditLimit !== null &&
        !!userOrganization &&
        !!userOrganization.creditInUseCad &&
        !!userOrganization.creditLimit &&
        !!selectedQuote &&
        (userOrganization.creditInUseCad >= userOrganization.creditLimit ||
            userOrganization.creditLimit - userOrganization.creditInUseCad < selectedQuote.costTotalCad)

    const isOrderNonSubmittable = !order.rfq?.originStreetAddress?.trim() ||
        !order.rfq?.destinationStreetAddress?.trim() ||
        userOrganization?.status === OrganizationStatus.INACTIVE ||
        userOrganization?.status === OrganizationStatus.BLOCKED ||
        reachedOrganizationMaxOrderLimit ||
        reachedOrganizationMaxCreditLimit

    let submitTooltip = 'Orders without a full address can be quoted and saved as drafts but cannot be submitted to a 3PL agent.'

    if (userOrganization?.status === OrganizationStatus.INACTIVE) {
        submitTooltip = 'Your organization has been deactivated. You may contact Lazr Support at support@lazr.io to request activation.'
    } else if (userOrganization?.status === OrganizationStatus.BLOCKED) {
        submitTooltip = 'Your organization has been blocked. You may contact Lazr Support at support@lazr.io to request activation.'
    } else if (reachedOrganizationMaxOrderLimit) {
        submitTooltip = `Your organization is limited to ${maxOrders ?? 'null'} ${isMailing ? 'mailing' : 'freight'} order.` +
            ' You may contact Lazr Support at support@lazr.io to request an increase.'
    } else if (reachedOrganizationMaxCreditLimit) {
        submitTooltip = `Your organization has a maximum credit limit of $${userOrganization?.creditLimit ?? ''}.` +
            ' You may contact Lazr Support at support@lazr.io to request an increase.'
    }

    const isTwoButtonConfiguration = (currentStep === MarketPlaceStep.MARKETPLACE && lastCompletedStep < MarketPlaceStep.MARKETPLACE)
        || currentStep === MarketPlaceStep.CONFIRMATION

    const totalWeightUnit = order.rfq?.handlingUnits?.some((handlingUnit) =>
        handlingUnit.totalWeightUnit === WeightUnit.LB) ? WeightUnit.LB : WeightUnit.KG

    const totalWeight = round(order.rfq?.handlingUnits?.reduce((total: number,
        handlingUnit: Partial<SearchRfqHandlingUnit>): number => {
        let handlingUnitTotalWeight = handlingUnit.totalWeight
        if (!handlingUnitTotalWeight) {
            return total
        }

        if (handlingUnit.totalWeightUnit !== totalWeightUnit) {
            if (totalWeightUnit === WeightUnit.LB) {
                handlingUnitTotalWeight = convert(handlingUnitTotalWeight).from('kg').to('lb')
            } else if (totalWeightUnit === WeightUnit.KG) {
                handlingUnitTotalWeight = convert(handlingUnitTotalWeight).from('lb').to('kg')
            }
        }

        return handlingUnitTotalWeight + total
    }, 0) || 0)

    const cargoInformation = getCargoInformation(order.rfq?.handlingUnits ?? [], packageTypeList)
    const linearFeetInformation = order.rfq?.linearFeet ? `${order.rfq.linearFeet} linear feet` : ''

    let progressSummaryCargoItem
    if (cargoInformation && linearFeetInformation) {
        progressSummaryCargoItem = `${cargoInformation}, ${linearFeetInformation}`
    } else if (cargoInformation) {
        progressSummaryCargoItem = cargoInformation
    } else {
        progressSummaryCargoItem = linearFeetInformation
    }

    const originAddressInformation = getAddressInformation(order.rfq?.originCity, order.rfq?.originState, order.rfq?.originPostalCode)
    const destinationAddressInformation =
        getAddressInformation(order.rfq?.destinationCity, order.rfq?.destinationState, order.rfq?.destinationPostalCode)

    const transitDays = formatTransitTime(order.rfq?.selectedQuote?.transitDaysMin, order.rfq?.selectedQuote?.transitDaysMax)

    const sectionA: ProgressSummaryInformationData[] = [
        { title: 'Origin city:', value: originAddressInformation || 'Not selected' },
        { title: 'Destination city:', value: destinationAddressInformation || 'Not selected' },
        { title: 'Cargo:', value: progressSummaryCargoItem ? progressSummaryCargoItem : 'Not selected' },
        {
            title: 'Total shipment weight:',
            value: totalWeight ? `${totalWeight} ${totalWeightUnit.toLocaleLowerCase()}${totalWeight !== 1 ? 's' : ''}` : 'Not selected',
        },
        {
            title: 'Ready date:', value: order.rfq?.originPickupDate ?
                moment(order.rfq.originPickupDate).format('MMM D, yyyy') : 'Not selected',
        },
    ]
    const sectionB: ProgressSummaryInformationData[] = [
        {
            title: 'Transport type:',
            value: order.rfq?.transportType ? HANDLING_TRANSPORT_TYPE[order.rfq.transportType] : 'Not selected',
        },
        {
            title: 'Equipment type:',
            value: order.rfq?.equipmentType ? HANDLING_EQUIPMENT_TYPE[order.rfq.equipmentType] : 'Not selected',
        },
        { title: 'Service selected:', value: order.rfq?.selectedQuote?.serviceLevel || 'Not selected' },
        { title: 'Carrier:', value: order.rfq?.selectedQuote?.carrierDisplayName || 'Not selected' },
        {
            title: 'Estimated transit:',
            value:
                <React.Fragment>
                    {transitDays || 'Not selected'}
                    &nbsp;
                    {transitDays &&
                        <Tooltip
                            placement='top'
                            title={EST_TRANSIT_TOOLTIP_TEXT}
                        >
                            <span>
                                <TransitDaysTooltipIcon icon={[ 'fas', 'info-circle' ]}/>
                            </span>
                        </Tooltip>
                    }
                </React.Fragment>,
        },
    ]

    const addressChanged = (address: Partial<CompleteOrderInformation>): boolean => {
        const a = address
        const b = a.addressBookAddress

        return !b ? false : !(
            a.streetAddress === b.streetAddressLine1
            &&
            a.streetAddress2 === b.streetAddressLine2
            &&
            a.city === b.city
            &&
            a.state === b.state
            &&
            a.country?.code === b.country.code
            &&
            a.postalCode === b.postalCode
            &&
            a.addressType === b.addressType
            &&
            a.companyName === b.companyName
            &&
            a.contactName === b.shippingContactName
            &&
            (a.contactEmails ? a.contactEmails.sort().join(',') : '') ===
            (b?.shippingContactEmails ? b.shippingContactEmails.sort().join(',') : '')
            &&
            a.contactPhone === b.shippingContactPhone
            &&
            a.phoneExtension === b.shippingContactPhoneExtension
            &&
            !a.notify === !b.notify
            &&
            a.openTime === b.openTime
            &&
            a.closeTime === b.closeTime
        )
    }

    const addressCollectInfoChanged = (address: Partial<CompleteOrderInformation>): boolean => {
        const existingAddress = address.addressBookAddress
        const transportType = order.rfq?.transportType
        if (!transportType || paymentType !== PaymentTypeOption.COLLECT || !collect) {
            return false
        } else if (!existingAddress || !existingAddress.collectAccounts || existingAddress.collectAccounts.length === 0) {
            return true
        }

        const collectAccount = existingAddress.collectAccounts
            .find((tempCollectAccount) => tempCollectAccount.carrier.id === collect.carrier.id &&
                tempCollectAccount.accountType === ([ TransportType.PARCEL, TransportType.PAK ].includes(transportType) ?
                    CollectAccountType.MAILING : CollectAccountType.FREIGHT))

        return !collectAccount ? true : (collectAccount.accountNumber !== collect.accountNumber)
    }

    const saveAddressToAddressBook = async (address: SaveableAddress, type: AccessorialLocationType) => {
        const addressAttributes = {
            addressId: address.id ?? undefined,
            userSpecifiedId: address.name,
            description: address.description,
            streetAddress: address.streetAddressLine1,
            streetAddress2: address.streetAddressLine2 ?? null,
            streetAddress3: address.streetAddressLine3 ?? null,
            city: address.city,
            state: address.state,
            country: address.country.code ?? null,
            postalCode: address.postalCode,
            addressType: address.addressType,
            detectedAddressType: address.detectedAddressType,
            companyName: address.companyName,
            shippingContactName: address.shippingContactName,
            shippingContactEmails: address.shippingContactEmails ?? null,
            shippingContactPhone: address.shippingContactPhone ?? undefined,
            shippingContactPhoneExtension: address.shippingContactPhoneExtension,
            billingContactName: address.billingContactName ?? null,
            billingContactEmails: address.billingContactEmails ?? null,
            billingContactPhone: address.billingContactPhone ?? null,
            billingContactPhoneExtension: address.billingContactPhoneExtension ?? null,
            notify: address.notify,
            instructions: address.instructions,
            openTime: address.openTime ?? null,
            closeTime: address.closeTime ?? null,
            accessorials: address.accessorials,
            isShipping: address.isShipping,
            isBilling: address.isBilling,
            isBillingDefault: address.isBillingDefault,
            isShippingDefault: address.isShippingDefault,
            currency: user?.currency?.code ?? defaultUserCurrency,
            collectAccounts: address.collectAccounts,
        }

        if (addressAttributes.addressId) {
            await AddressApiService.edit(addressAttributes, true)
            if (type === AccessorialLocationType.ORIGIN) {
                dispatch(reduxSetOriginAddressBookAddress(address as Address))
            } else {
                dispatch(reduxSetDestinationAddressBookAddress(address as Address))
            }
        } else {
            const id = await AddressApiService.add(addressAttributes)

            if (type === AccessorialLocationType.ORIGIN) {
                dispatch(reduxSetOriginAddressBookAddress({
                    ...address,
                    id,
                } as Address))
            } else {
                dispatch(reduxSetDestinationAddressBookAddress({
                    ...address,
                    id,
                } as Address))
            }
        }
    }

    const startSpinning = () => {
        setChangingStep(true)
        setNextButtonIconProp(SPINNER_ICON)
    }

    const stopSpinning = () => {
        setChangingStep(false)
        setNextButtonIconProp(NEXT_ICON)
    }

    const validateCompleteOrder = (): boolean => {
        const nextStep: MarketPlaceStep = currentStep + 1
        if (!MARKETPLACE_ROUTES[nextStep]) {
            logger.error(`goToNextStep: page ${nextStep} doesn't exist`)

            return false
        }

        startSpinning()

        if (!isOrderNonSubmittable && !order.rfq?.customQuoteRequested) {
            const hasErrors = validateCompleteOrderStep(true)
            if (hasErrors) {
                stopSpinning()

                return false
            }
        }

        stopSpinning()

        return true
    }

    const proceedCompleteOrder = () => {
        const nextStep: MarketPlaceStep = currentStep + 1
        if (!orderId) {
            logger.error(`completeOrderInformation: orderId is empty in page ${currentStep}`)
            resetMarketplace()

            return
        }

        localStorage.setItem('marketPlaceStep', currentStep.toString())
        localStorage.setItem('marketPlaceOrderId', orderId)

        dispatch(reduxSetMarketPlaceLastCompletedStep(currentStep))

        history.push(`${MARKETPLACE_ROUTES[nextStep]}/${String(orderId)}`)
    }

    const afterAddressSave = () => {
        if (providerSupportSchedulePickup) {
            setIsSchedulePickupOpened(true)

            return
        }
        if (!isEtdAlwaysEnabled && providerSupportsEtd && internationalOrder) {
            setShowEtdModal(true)

            return
        }

        proceedCompleteOrder()
    }

    const goToNextStep = async (): Promise<void> => {
        if (!orderId) {
            logger.error(`goToNextPage: orderId is empty in page ${currentStep}`)
            resetMarketplace()

            return
        }

        const nextStep: MarketPlaceStep = currentStep + 1
        if (!MARKETPLACE_ROUTES[nextStep]) {
            logger.error(`goToNextStep: page ${nextStep} doesn't exist`)

            return
        }

        if (currentStep === MarketPlaceStep.COMPLETE_ORDER) {
            if (!user) {
                if (validateCompleteOrder()) {
                    proceedCompleteOrder()
                }

                return
            }

            const addressesToSave: typeof addressBookAddressesToSave = []

            const processAddress = async (
                address: Partial<CompleteOrderInformation>,
                type: AccessorialLocationType,
            ) => {
                const isOrigin = type === AccessorialLocationType.ORIGIN
                const label = isOrigin ? 'Origin' : 'Destination'
                let addressBookAddressExists = !!address.addressBookAddress
                if(!address.addressBookAddress) {
                    const foundMatchedAddresses = await AddressApiService.list({
                            page: 1,
                            resultPerPage: 5,
                        },
                        {
                            address: address.streetAddress ?? undefined,
                            city: address.city ?? undefined,
                            state: address.state ?? undefined,
                            postalCode: address.postalCode ?? undefined,
                            country: address.country?.code ?? undefined,
                            companyName: address.companyName ?? undefined,
                            organizationId: userOrganization?.id ?? undefined,
                            isShipping: true,
                        })
                    if(foundMatchedAddresses && foundMatchedAddresses?.addresses.length > 0) {
                        if(isOrigin) {
                            dispatch(reduxSetOriginAddressBookAddress(address as Address))
                        }
                        else {
                            dispatch(reduxSetDestinationAddressBookAddress(address as Address))
                        }
                        addressBookAddressExists = true
                    }




                }



                const addressBookAddressChanged = addressBookAddressExists && addressChanged(address)
                const addressTypeChanged = !!address.addressBookAddress && address.addressType !== address.addressBookAddress.addressType
                const addressBookAddressSave = !!address.saveToAddressBook && user.permissions.viewCompanyAddressBook
                const addressBookCollectInfoChanged = !isOrigin && user.permissions.viewCompanyAddressBook &&
                    addressCollectInfoChanged(address)

                // window.console.log('--------------------------------')
                // window.console.log(label)
                // window.console.log(address)
                // window.console.log('save', addressBookAddressSave)
                // window.console.log('exists', addressBookAddressExists)
                // window.console.log('changed', addressBookAddressChanged)
                // window.console.log('--------------------------------')

                const processCollectInformation = (): CollectAccount[] | null => {
                    if (!order.rfq?.transportType) {
                        return null
                    }
                    const collectAccounts = address.addressBookAddress?.collectAccounts
                    const accountType = [ TransportType.PARCEL, TransportType.PAK ].includes(order.rfq.transportType) ?
                        CollectAccountType.MAILING : CollectAccountType.FREIGHT
                    if (!isOrigin && paymentType === PaymentTypeOption.COLLECT && collect && order.rfq?.transportType &&
                        (!collectAccounts || collectAccounts.length === 0)) {
                        return [
                            {
                                ...collect,
                                carrier: omit(collect.carrier, [ 'transportType', 'organizationProviderAccounts' ]) as Carrier,
                                accountType,
                            },
                        ]
                    } else if (!isOrigin && paymentType === PaymentTypeOption.COLLECT && collect && order.rfq?.transportType &&
                        (collectAccounts && collectAccounts.length > 0)) {
                        const collectAccount = collectAccounts
                            .find((tempCollectAccount) => tempCollectAccount.carrier.id === collect.carrier.id &&
                            tempCollectAccount.accountType === accountType)

                        if (collectAccount) {
                            return [
                                ...(collectAccounts
                                    .filter((tempCollectAccount) => collectAccount.id !== tempCollectAccount.id)
                                    .map((tempCollectAccount) => omit(tempCollectAccount, [ 'id' ])) as CollectAccount[]),
                                {
                                    ...collect,
                                    accountType: collectAccount.accountType,
                                    carrier: omit(collect.carrier, [ 'transportType', 'organizationProviderAccounts' ])  as Carrier,
                                },
                            ]
                        } else {
                            return [
                                ...collectAccounts
                                    .map((tempCollectAccount) => omit(tempCollectAccount, [ 'id' ])) as CollectAccount[],
                                {
                                    ...collect,
                                    carrier: omit(collect.carrier, [ 'transportType', 'organizationProviderAccounts' ]) as Carrier,
                                    accountType: accountType,
                                },
                            ]
                        }
                    } else {
                        return collectAccounts ? [
                            ...collectAccounts
                                .map((tempCollectAccount) => omit(tempCollectAccount, [ 'id' ])) as CollectAccount[],
                        ] : null
                    }
                }

                if (!addressBookAddressExists || addressBookAddressChanged || addressBookCollectInfoChanged) {
                    const addressToSave: SaveableAddress = {
                        id:
                            address.addressBookAddress?.id
                            ??
                            '',
                        name:
                            address.addressBookAddress?.name
                            ??
                            'Auto-Added',
                        organizationId: address.addressBookAddress?.organizationId
                            ??
                            '',
                        description:
                            address.addressBookAddress?.description
                            ??
                            `Auto-added on ${moment().format('YYYY-MM-DD')}`,
                        streetAddressLine1:
                            address.streetAddress
                            ??
                            address.addressBookAddress?.streetAddressLine1
                            ??
                            '',
                        streetAddressLine2:
                            address.streetAddress2
                            ??
                            address.addressBookAddress?.streetAddressLine2
                            ??
                            null,
                        streetAddressLine3:
                            address.streetAddress3
                            ??
                            address.addressBookAddress?.streetAddressLine3
                            ??
                            null,
                        city:
                            address.city
                            ??
                            address.addressBookAddress?.city
                            ??
                            '',
                        state:
                            address.state
                            ??
                            address.addressBookAddress?.state
                            ??
                            '',
                        country: (
                            address.country
                            ??
                            address.addressBookAddress?.country
                        ) as Country,
                        postalCode:
                            address.postalCode
                            ??
                            address.addressBookAddress?.postalCode
                            ??
                            '',
                        addressType:
                            address.addressType
                            ??
                            address.addressBookAddress?.addressType
                            ??
                            AddressType.BUSINESS,
                        detectedAddressType:
                            address.detectedAddressType
                            ??
                            address.addressBookAddress?.detectedAddressType,
                        companyName:
                            address.companyName
                            ??
                            address.addressBookAddress?.companyName
                            ??
                            '',
                        companyLegalName:
                            address.addressBookAddress?.companyLegalName
                            ??
                            address.companyName
                            ??
                            '',
                        shippingContactPhone:
                            address.contactPhone
                            ??
                            address.addressBookAddress?.shippingContactPhone
                            ??
                            '',
                        shippingContactPhoneExtension:
                            address.phoneExtension
                            ??
                            address.addressBookAddress?.shippingContactPhoneExtension
                            ??
                            '',
                        shippingContactName:
                            address.contactName
                            ??
                            address.addressBookAddress?.shippingContactName
                            ??
                            '',
                        shippingContactEmails:
                            address.contactEmails ??
                            address.addressBookAddress?.shippingContactEmails
                            ??
                            [],
                        billingContactPhone:
                            address.addressBookAddress?.billingContactPhone
                            ??
                            '',
                        billingContactPhoneExtension:
                            address.addressBookAddress?.billingContactPhoneExtension
                            ??
                            '',
                        billingContactName:
                            address.addressBookAddress?.billingContactName
                            ??
                            '',
                        billingContactEmails:
                            address.addressBookAddress?.billingContactEmails
                            ??
                            [],
                        notify:
                            address.notify
                            ??
                            address.addressBookAddress?.notify
                            ??
                            false,
                        instructions:
                            address.addressBookAddress?.instructions
                            ??
                            '',
                        openTime:
                            address.openTime
                            ??
                            address.addressBookAddress?.openTime
                            ??
                            '',
                        closeTime:
                            address.closeTime
                            ??
                            address.addressBookAddress?.closeTime
                            ??
                            '',
                        accessorials: (addressTypeChanged || !addressBookAddressExists) ? getAddressBookAccessorials(address) :
                            address.addressBookAddress?.accessorials
                            ??
                            [],
                        isShipping:
                            address.addressBookAddress?.isShipping
                            ??
                            true,
                        isShippingDefault:
                            address.addressBookAddress?.isShippingDefault
                            ??
                            false,
                        isBilling:
                            address.addressBookAddress?.isBilling
                            ??
                            false,
                        isBillingDefault:
                            address.addressBookAddress?.isBillingDefault
                            ??
                            false,
                        collectAccounts: processCollectInformation(),
                    }
                    if (!addressBookAddressExists && !isAddressComplete(addressToSave)) {
                        return
                    }

                    if (addressBookAddressSave) {
                        try {
                            await saveAddressToAddressBook(addressToSave, type)
                        } catch (e: any) {
                            console.error(e)
                            dispatch(reduxSetSnackbar({
                                autoHideDuration: 5000,
                                message: `Unexpected error! Could not save ${type ? type.toLowerCase() : ''} address!`,
                                open: true,
                                origin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                                severity: 'error',
                            }))
                        }
                    } else if (address.addressBookAddress?.isBilling) {
                        addressesToSave.push({
                            title: `${label} address picked from address book was changed`,
                            message: 'Can\'t update address since it is a billing address.',
                            address: null,
                            type,
                        })
                    } else {
                        addressesToSave.push({
                            ...(
                                addressBookAddressExists
                                    ? {
                                        title: `${label} address picked from address book was changed`,
                                        message: `Do you want to save the changes${addressBookCollectInfoChanged ?
                                            ' and attach the collect account data to it' : ''}?`,
                                    }
                                    : {
                                        title: `${label} address does not exist in your address book`,
                                        message: `Do you want to add it${addressBookCollectInfoChanged ?
                                            ' and attach the collect account data to it' : ''}?`,
                                    }
                            ),
                            address: addressToSave,
                            type,
                        })
                    }
                }
            }

            if (validateCompleteOrder()) {
                await processAddress(originAddress, AccessorialLocationType.ORIGIN)
                await processAddress(destinationAddress, AccessorialLocationType.DESTINATION)

                if (addressesToSave.length > 0 && !marketplace.isPrepared && user.permissions.viewCompanyAddressBook) {
                    setAddressBookAddressesToSave(addressesToSave)
                    setIsConfirmSavingAddressToAddressBookOpened(true)
                } else {
                    afterAddressSave()
                }
            }
        } else {
            history.push(`${MARKETPLACE_ROUTES[nextStep]}/${orderId}`)
        }
    }

    const getAddressBookAccessorials = (address: Partial<CompleteOrderInformation>): AddressAccessorial[] => {
        const addressType = (address.addressType ?? address.addressBookAddress?.addressType) ?? AddressType.BUSINESS
        const addressBookOriginAccessorial = address.addressBookAddress?.accessorials.filter((accessorial) =>
            accessorial.accessorial.locationType === AccessorialLocationType.ORIGIN) ?? []
        const addressBookDestinationAccessorial = address.addressBookAddress?.accessorials.filter((accessorial) =>
            accessorial.accessorial.locationType === AccessorialLocationType.DESTINATION) ?? []
        const addressBookAnyAccessorial = address.addressBookAddress?.accessorials.filter((accessorial) =>
            accessorial.accessorial.locationType === AccessorialLocationType.ANY) ?? []

        const newOriginAcessorials = addDefaultAccessorials(addressType, addressBookOriginAccessorial, originAccessorialList)
        const newDestinationAcessorials = addDefaultAccessorials(addressType, addressBookDestinationAccessorial, destinationAccessorialList)

        return newOriginAcessorials.concat(newDestinationAcessorials).concat(addressBookAnyAccessorial)
    }

    const now = moment()
    const willExpireDate = moment(order?.createdAt).add(5, 'days')
    const isExpired = willExpireDate.isSameOrBefore(now)
    const shownExpirationDate = willExpireDate.format('YYYY-MM-DD hh:mm:ss A')

    const isPastDateOrder = (pastDateOrder: Partial<Search>, action: () => Promise<void>): boolean => {
        if (validatePastDate(pastDateOrder?.rfq?.originPickupDate)) {
            setSubmitPastDateOrder(() => action)
            setOpenPastDateOrderModal(!openPastDateOrderModal)
        }

        return validatePastDate(pastDateOrder?.rfq?.originPickupDate)
    }

    const getCommodityTypes = () => {
        if (isMailing){
            return coverageCommodityTypeList.filter((e) => [
                CoverageCommodityType.GENERAL,
                CoverageCommodityType.FRAGILE,
                CoverageCommodityType.CONSUMER_ELECTRONICS,
            ].includes(e.code))
        }
        return coverageCommodityTypeList
    }

    const completeMarketplaceFlow = async (isDraft: boolean, isAddressValidationAccepted: boolean, byPassAddressValidation?: boolean): Promise<void> => {
        if (!orderId) {
            logger.error('Order id is not defined')
            dispatch(reduxSetSnackbar({
                autoHideDuration: 5000,
                message: 'Unable to submit order.',
                open: true,
                origin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                severity: 'error',
            }))
            resetMarketplace()

            return
        }

        try {
            const providerSpecific = createProviderSpecificObject(order, isDraft)
            if (!isDraft) {
                let submitOrderResponse
                try {
                    submitOrderResponse = await OrderApiService.submit(orderId,
                        {
                            schedulePickup: order.rfq?.schedulePickup ?? true,
                            byPassAddressValidation: byPassAddressValidation ?? false,
                            isAddressValidationAccepted: isAddressValidationAccepted,
                            validatedOriginAddress: selectedValidatedOriginAddress,
                            validatedDestinationAddress: selectedValidatedDestinationAddress,
                            currency: order.billingCurrency?.code,
                            clientNumber: order.clientNumber,
                            threePlNumber: order.threePlNumber,
                            originContactName: order.rfq?.originContactName,
                            originContactEmails: order.rfq?.originContactEmails,
                            originContactPhone: order.rfq?.originContactPhone,
                            originContactPhoneExtension: order.rfq?.originContactPhoneExtension,
                            originCompanyName: order.rfq?.originCompanyName,
                            originNotifyShipper: order.rfq?.originNotifyShipper,
                            originPickupOpenTime: order.rfq?.originPickupOpenTime,
                            originPickupCloseTime: order.rfq?.originPickupCloseTime,
                            originPickupInstructions: order.rfq?.originPickupInstructions,
                            originPoNumber: order.rfq?.originPoNumber,
                            destinationContactName: order.rfq?.destinationContactName,
                            destinationContactEmails: order.rfq?.destinationContactEmails,
                            destinationContactPhone: order.rfq?.destinationContactPhone,
                            destinationContactPhoneExtension: order.rfq?.destinationContactPhoneExtension,
                            destinationCompanyName: order.rfq?.destinationCompanyName,
                            destinationNotifyReceiver: order.rfq?.destinationNotifyReceiver,
                            destinationDeliveryOpenTime: order.rfq?.destinationDeliveryOpenTime,
                            destinationDeliveryCloseTime: order.rfq?.destinationDeliveryCloseTime,
                            destinationDeliveryInstructions: order.rfq?.destinationDeliveryInstructions,
                            destinationPoNumber: order.rfq?.destinationPoNumber,
                            pickupStreetAddress: order.rfq?.pickupStreetAddress ?? null,
                            pickupStreetAddress2: order.rfq?.pickupStreetAddress2 ?? null,
                            pickupStreetAddress3: order.rfq?.pickupStreetAddress3 ?? null,
                            pickupCity: order.rfq?.pickupCity ?? null,
                            pickupState: order.rfq?.pickupState ?? null,
                            pickupCountry: order.rfq?.pickupCountry?.code ?? null,
                            pickupPostalCode: order.rfq?.pickupPostalCode ?? null,
                            pickupAddressType: order.rfq?.pickupAddressType ?? null,
                            pickupContactName: order.rfq?.pickupContactName ?? null,
                            pickupContactEmails: order.rfq?.pickupContactEmails ?? null,
                            pickupContactPhone: order.rfq?.pickupContactPhone ?? null,
                            pickupContactPhoneExtension: order.rfq?.pickupContactPhoneExtension ?? null,
                            pickupCompanyName: order.rfq?.pickupCompanyName ?? null,
                            pickupNotify: order.rfq?.pickupNotify ?? false,
                            pickupOpenTime: order.rfq?.pickupOpenTime ?? null,
                            pickupCloseTime: order.rfq?.pickupCloseTime ?? null,
                            pickupInstructions: order.rfq?.pickupInstructions ?? null,
                            documentsOnlyIndicator: order.rfq?.documentsOnlyIndicator,
                            customsBroker: order.rfq?.customsBroker,
                            customQuoteRequested: order.rfq?.customQuoteRequested,
                            coverageCommodityType: order.rfq?.coverageCommodityType?.code,
                            shipmentCoverageValue: order.rfq?.shipmentCoverageValue,
                            isBookedWithCoverage: order.rfq?.isBookedWithCoverage,
                            selectedQuoteId: order.rfq?.selectedQuote?.id,
                            handlingUnits: order.rfq?.handlingUnits,
                            customsDetails: order.rfq?.customsDetails,
                            transportType: order.rfq?.transportType,
                            originCountry: order.rfq?.originCountry?.code,
                            destinationCountry: order.rfq?.destinationCountry?.code,
                            provider: order.rfq?.selectedQuote?.provider,
                            addressId: order.rfq?.addressId ?? null,
                            billingPo: order.rfq?.billingPo ?? null,
                            commercialInvoiceDocumentIndicator: order.rfq?.commercialInvoiceDocumentIndicator,
                            commercialInvoiceUserProvided: order.rfq?.commercialInvoiceUserProvided,
                            useThermalPrinter: order.rfq?.useThermalPrinter,
                            labelSizeType: order.rfq?.labelSizeType,
                            incotermsType: order.rfq?.incotermsType?.code ?? null,
                            ...providerSpecific as CanadaPostSpecifics | FedexSpecifics | PurolatorSpecifics | CanparSpecifics | UpsSpecifics | DhlSpecifics | RoseRocketSpecifics | P44Specifics | GlsCanadaSpecifics,
                            taxIdentificationNumber: order.rfq?.taxIdentificationNumber ?? null,
                            consigneeTaxIdentificationNumber: order.rfq?.consigneeTaxIdentificationNumber ?? null,
                            thirdPartyTaxIdentificationNumber: order.rfq?.thirdPartyTaxIdentificationNumber ?? null,
                            shipmentDocumentType: order.rfq?.shipmentDocumentType ?? null,
                        },
                    )
                } catch (error: any) {
                    logger.debug(`submit ${error.message as string}`)
                    if (error.message === 'Expired order') {
                        setShowExpiredModal(true)
                    } else if (error.message?.includes('default billing address')) {
                        setShowBillingAddressModal(true)
                    } else if (error.message?.includes('organization registration information')) {
                        setRegisterModalAction(RegisterModalAction.SUBMIT)
                        setShowRegisterModal(true)
                    } else if (error.message?.includes('Your organization is inactive. Contact your administrator.')) {
                        dispatch(reduxSetSnackbar({
                            autoHideDuration: null,
                            message: 'Your organization is inactive. Contact your administrator.',
                            open: true,
                            origin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            severity: 'error',
                        }))
                    } else if (error.message?.includes(UNAPPROVED_ORGANIZATION_MESSAGE)) {
                        dispatch(reduxSetSnackbar({
                            autoHideDuration: null,
                            message: UNAPPROVED_ORGANIZATION_MESSAGE,
                            open: true,
                            origin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            severity: 'error',
                        }))
                    } else if (error.message?.includes('Your organization is blocked for the following reason:')) {
                        dispatch(reduxSetSnackbar({
                            autoHideDuration: null,
                            message: error.message,
                            open: true,
                            origin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            severity: 'error',
                        }))
                    } else if (error.message?.includes('Your organization is limited to')) {
                        dispatch(reduxSetSnackbar({
                            autoHideDuration: null,
                            message: error.message,
                            open: true,
                            origin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            severity: 'error',
                        }))
                    } else if (error.message?.includes('Your organization has a maximum credit limit')) {
                        dispatch(reduxSetSnackbar({
                            autoHideDuration: null,
                            message: error.message,
                            open: true,
                            origin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            severity: 'error',
                        }))
                    }

                    return
                }
                if (submitOrderResponse.newPickupDate) {
                    dispatch(setNewPickupDate(submitOrderResponse.newPickupDate))

                    return
                }

                if (
                    submitOrderResponse.isClientOriginAddressConfirmationRequired ||
                    submitOrderResponse.isClientDestinationAddressConfirmationRequired
                ) {
                    if ((submitOrderResponse?.updatedOriginAddresses && submitOrderResponse.updatedOriginAddresses.length > 0) ||
                        (submitOrderResponse?.updatedDestinationAddresses && submitOrderResponse.updatedDestinationAddresses?.length > 0)) {
                        setCarrierSuggestedAddresses({
                            originAddresses:
                                submitOrderResponse.isClientOriginAddressConfirmationRequired &&
                                submitOrderResponse.updatedOriginAddresses ?
                                    submitOrderResponse.updatedOriginAddresses : null,
                            destinationAddresses: submitOrderResponse.isClientDestinationAddressConfirmationRequired &&
                            submitOrderResponse.updatedDestinationAddresses ?
                                submitOrderResponse.updatedDestinationAddresses : null,
                        })
                    }

                    setAddressValidationModalOpen(true)

                    return
                }
            } else {
                try {
                    await OrderApiService.draft(orderId,
                        {
                            schedulePickup: order.rfq?.schedulePickup ?? true,
                            byPassAddressValidation: byPassAddressValidation ?? false,
                            isAddressValidationAccepted: isAddressValidationAccepted,
                            validatedOriginAddress: selectedValidatedOriginAddress,
                            validatedDestinationAddress: selectedValidatedDestinationAddress,
                            currency: order.billingCurrency?.code,
                            clientNumber: order.clientNumber,
                            threePlNumber: order.threePlNumber,
                            addressId: order.rfq?.addressId,
                            billingPo: order.rfq?.billingPo,
                            originContactName: order.rfq?.originContactName,
                            originContactEmails: order.rfq?.originContactEmails,
                            originContactPhone: order.rfq?.originContactPhone,
                            originContactPhoneExtension: order.rfq?.originContactPhoneExtension,
                            originCompanyName: order.rfq?.originCompanyName,
                            originNotifyShipper: order.rfq?.originNotifyShipper,
                            originPickupOpenTime: order.rfq?.originPickupOpenTime,
                            originPickupCloseTime: order.rfq?.originPickupCloseTime,
                            originPickupInstructions: order.rfq?.originPickupInstructions,
                            originPoNumber: order.rfq?.originPoNumber,
                            destinationContactName: order.rfq?.destinationContactName,
                            destinationContactEmails: order.rfq?.destinationContactEmails,
                            destinationContactPhone: order.rfq?.destinationContactPhone,
                            destinationContactPhoneExtension: order.rfq?.destinationContactPhoneExtension,
                            destinationCompanyName: order.rfq?.destinationCompanyName,
                            destinationNotifyReceiver: order.rfq?.destinationNotifyReceiver,
                            destinationDeliveryOpenTime: order.rfq?.destinationDeliveryOpenTime,
                            destinationDeliveryCloseTime: order.rfq?.destinationDeliveryCloseTime,
                            destinationDeliveryInstructions: order.rfq?.destinationDeliveryInstructions,
                            destinationPoNumber: order.rfq?.destinationPoNumber,
                            customsBroker: order.rfq?.customsBroker,
                            customQuoteRequested: order.rfq?.customQuoteRequested,
                            coverageCommodityType: order.rfq?.coverageCommodityType?.code,
                            shipmentCoverageValue: order.rfq?.shipmentCoverageValue,
                            isBookedWithCoverage: order.rfq?.isBookedWithCoverage,
                            selectedQuoteId: order.rfq?.selectedQuote?.id,
                            documentsOnlyIndicator: order.rfq?.documentsOnlyIndicator,
                            handlingUnits: order.rfq?.handlingUnits,
                            customsDetails: order.rfq?.customsDetails,
                            transportType: order.rfq?.transportType,
                            originCountry: order.rfq?.originCountry?.code,
                            destinationCountry: order.rfq?.destinationCountry?.code,
                            provider: order.rfq?.selectedQuote?.provider,
                            commercialInvoiceDocumentIndicator: order.rfq?.commercialInvoiceDocumentIndicator,
                            commercialInvoiceUserProvided: order.rfq?.commercialInvoiceUserProvided,
                            useThermalPrinter: order.rfq?.useThermalPrinter,
                            labelSizeType: order.rfq?.labelSizeType,
                            incotermsType: order.rfq?.incotermsType?.code ?? null,
                            shipmentDocumentType: order.rfq?.shipmentDocumentType ?? null,
                            ...providerSpecific as CanadaPostSpecifics | FedexSpecifics | PurolatorSpecifics | CanparSpecifics | UpsSpecifics | DhlSpecifics | RoseRocketSpecifics | P44Specifics | GlsCanadaSpecifics,
                        },
                    )
                } catch (error: any) {
                    if (error.message === 'Expired order') {
                        setShowExpiredModal(true)
                    }

                    if (error.message?.includes('organization registration information')) {
                        setRegisterModalAction(RegisterModalAction.DRAFT)
                        setShowRegisterModal(true)
                    }

                    return
                }
            }
        } catch (error: any) {
            const replyMessage = `Unable to ${isDraft ? 'draft' : 'submit'} order: ${error.message as string}`
            logger.error(replyMessage)
            logger.error(error.message)
            dispatch(reduxSetSnackbar({
                autoHideDuration: 5000,
                message: replyMessage,
                open: true,
                origin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                severity: 'error',
            }))

            return
        }

        dispatch(reduxSetMarketPlaceLastCompletedStep(currentStep))
        cleanupMarketplace()
    }
    const search = async (isCustomQuote = false, isCoverageRequested = false): Promise<Search | null> => {
        let newOrder: Search
        try {
            const billingCurrency = order.billingCurrency?.code
                // fallback to user's explicitly-defined default billing currency
                ?? user?.defaultBillingCurrency?.code
                // fallback to organization's defined default billing currency for all users
                ?? userOrganization?.defaultBillingCurrency?.code
                // fallback to user's display currency, which internally also falls back to CAD if unspecified at all
                ?? user?.currency?.code
                ?? defaultUserCurrency
            newOrder = await SearchApiService.search({
                preparedSearchId: query.get('preparedSearchId') ?? undefined,
                customQuoteRequested: isCustomQuote,
                isBookedWithCoverage: isCoverageRequested,
                ediOrderId: order.ediOrderId,
                transportType: order.rfq?.transportType,
                equipmentType: order.rfq?.equipmentType,
                coverageCommodityType: order.rfq?.coverageCommodityType?.code,
                shipmentCoverageValue: order.rfq?.shipmentCoverageValue,
                originStreetAddress: order.rfq?.originStreetAddress,
                originStreetAddress2: order.rfq?.originStreetAddress2,
                originCity: order.rfq?.originCity,
                originState: order.rfq?.originState,
                originCountry: order.rfq?.originCountry?.code,
                originPostalCode: order.rfq?.originPostalCode,
                originAddressType: order.rfq?.originAddressType,
                originDetectedAddressType: order.rfq?.originDetectedAddressType,
                originPickupDate: order.rfq?.originPickupDate,
                originPickupDateTimezone: getBrowserTimezone(),
                originContactName: order.rfq?.originContactName,
                originContactEmails: order.rfq?.originContactEmails,
                originContactPhone: order.rfq?.originContactPhone,
                originContactPhoneExtension: order.rfq?.originContactPhoneExtension,
                originCompanyName: order.rfq?.originCompanyName,
                originNotifyShipper: order.rfq?.originNotifyShipper ?? false,
                originPickupOpenTime: order.rfq?.originPickupOpenTime,
                originPickupCloseTime: order.rfq?.originPickupCloseTime,
                originPickupInstructions: order.rfq?.originPickupInstructions,
                originPoNumber: order.rfq?.originPoNumber,
                destinationStreetAddress: order.rfq?.destinationStreetAddress,
                destinationStreetAddress2: order.rfq?.destinationStreetAddress2,
                destinationCity: order.rfq?.destinationCity,
                destinationState: order.rfq?.destinationState,
                destinationCountry: order.rfq?.destinationCountry?.code,
                destinationPostalCode: order.rfq?.destinationPostalCode,
                destinationAddressType: order.rfq?.destinationAddressType,
                destinationDetectedAddressType: order.rfq?.destinationDetectedAddressType,
                destinationContactName: order.rfq?.destinationContactName,
                destinationContactEmails: order.rfq?.destinationContactEmails,
                destinationContactPhone: order.rfq?.destinationContactPhone,
                destinationContactPhoneExtension: order.rfq?.destinationContactPhoneExtension,
                destinationCompanyName: order.rfq?.destinationCompanyName,
                destinationNotifyReceiver: order.rfq?.destinationNotifyReceiver ?? false,
                destinationDeliveryOpenTime: order.rfq?.destinationDeliveryOpenTime,
                destinationDeliveryCloseTime: order.rfq?.destinationDeliveryCloseTime,
                destinationDeliveryInstructions: order.rfq?.destinationDeliveryInstructions,
                destinationPoNumber: order.rfq?.destinationPoNumber,
                billingCurrency: billingCurrency,
                originAccessorials: originAccessorials,
                destinationAccessorials: destinationAccessorials,
                cargo: order.rfq?.handlingUnits?.map((hu) => ({
                    ...hu,
                    accessorials: hu.accessorials && hu.accessorials.length > 0 ? hu.accessorials.map((acc) => ({
                        ...acc,
                        accessorialId: acc.accessorial.id,
                    })) : hu.accessorials,
                    length: hu.length?  hu.length : 0,
                    lengthUnit: hu.lengthUnit?  hu.lengthUnit : (hu.weightUnit === WeightUnit.KG ? DimensionUnit.CM : DimensionUnit.IN),
                    width: hu.width?  hu.width : 0,
                    widthUnit: hu.widthUnit?  hu.widthUnit : (hu.weightUnit === WeightUnit.KG ? DimensionUnit.CM : DimensionUnit.IN),
                    height: hu.length?  hu.height : 0,
                    heightUnit: hu.heightUnit?  hu.heightUnit : (hu.weightUnit === WeightUnit.KG ? DimensionUnit.CM : DimensionUnit.IN),
                })),
                clientNumber: order.clientNumber,
                threePlNumber: order.threePlNumber,
                customsBroker: order.rfq?.customsBroker,
                billDutiesToParty: order.rfq?.billDutiesToParty?.code,
                documentsOnlyIndicator: order.rfq?.documentsOnlyIndicator,
                customsTotalValue: order.rfq?.customsTotalValue,
                dutyBillToCity: order.rfq?.dutyBillToCity,
                dutyBillToContactEmail: order.rfq?.dutyBillToContactEmail,
                dutyBillToCompanyName: order.rfq?.dutyBillToCompanyName,
                dutyBillToContactName: order.rfq?.dutyBillToContactName,
                dutyBillToContactPhone: order.rfq?.dutyBillToContactPhone,
                dutyBillToContactPhoneExt: order.rfq?.dutyBillToContactPhoneExt,
                dutyBillToCountry: order.rfq?.dutyBillToCountry?.code,
                dutyBillToName: order.rfq?.dutyBillToName,
                dutyBillToPostalCode: order.rfq?.dutyBillToPostalCode,
                dutyBillToState: order.rfq?.dutyBillToState,
                dutyBillToStreetAddress: order.rfq?.dutyBillToStreetAddress,
                dutyBillToStreetAddress2: order.rfq?.dutyBillToStreetAddress2,
                dutyBillToAccountNumber: order.rfq?.dutyBillToAccountNumber,
                dutyBusinessRelated: order.rfq?.dutyBusinessRelated,
                dutyCurrency: order.rfq?.dutyCurrency?.code,
                hazmatClass: order.rfq?.hazmatClass?.code,
                hazmatDeclarationDocumentIndicator: order.rfq?.hazmatDeclarationDocumentIndicator,
                hazmatMode: order.rfq?.hazmatMode?.code,
                importExportType: order.rfq?.importExport?.code,
                commercialInvoiceDocumentIndicator: order.rfq?.commercialInvoiceDocumentIndicator,
                commercialInvoiceUserProvided: order.rfq?.commercialInvoiceUserProvided,
                useThermalPrinter: order.rfq?.useThermalPrinter,
                labelSizeType: order.rfq?.labelSizeType,
                b13aFilingOption: order.rfq?.b13aFilingOption?.code,
                exportComplianceStatement: order.rfq?.exportComplianceStatement?.code,
                customsBrokerCity: order.rfq?.customsBrokerCity,
                customsBrokerEmail: order.rfq?.customsBrokerEmail,
                customsBrokerContactName: order.rfq?.customsBrokerContactName,
                customsBrokerContactPhone: order.rfq?.customsBrokerContactPhone,
                customsBrokerContactPhoneExt: order.rfq?.customsBrokerContactPhoneExt,
                customsBrokerCountry: order.rfq?.customsBrokerCountry?.code,
                customsBrokerPostalCode: order.rfq?.customsBrokerPostalCode,
                customsBrokerState: order.rfq?.customsBrokerState,
                customsBrokerStreetAddress: order.rfq?.customsBrokerStreetAddress,
                customsBrokerStreetAddress2: order.rfq?.customsBrokerStreetAddress2,
                customsBrokerAccountNumber: order.rfq?.customsBrokerAccountNumber,
                taxIdentificationNumber: order.rfq?.taxIdentificationNumber,
                consigneeTaxIdentificationNumber: order.rfq?.consigneeTaxIdentificationNumber,
                thirdPartyTaxIdentificationNumber: order.rfq?.thirdPartyTaxIdentificationNumber,
                reasonForExportType: order.rfq?.reasonForExport?.code,
                nonDeliveryHandlingType: order.rfq?.nonDeliveryHandling?.code,
                otherReasonForExport: order.rfq?.otherReasonForExport,
                incotermsType: order.rfq?.incotermsType?.code,
                collectData: collect && collect?.accountNumber && collect?.carrier ? {
                    accountNumber: collect.accountNumber,
                    carrierId: collect.carrier.id,
                } : undefined,
                paymentType,
                selectedLaneId: selectedLane ? selectedLane.id : undefined,
                shipmentDocumentType: order.rfq?.shipmentDocumentType ?? null,
                holdForPickup: order.rfq?.holdForPickup?.address?.companyName ? order.rfq?.holdForPickup : {
                    carrierId: order.rfq?.holdForPickup?.carrierId ?? null,
                    address: null
                },
            })

            return newOrder
        } catch (error: any) {
            logger.error('Unable to search for quotes')
            logger.error(error.message)
            logger.error(error.stack)

            return null
        }
    }

    const handleLazrIt = async (): Promise<void> => {
        setStartingSearch(true)

        const newOrder = await search()
        if (!newOrder) {
            setStartingSearch(false)

            dispatch(reduxSetSnackbar({
                autoHideDuration: 5000,
                message: 'Unable to proceed.',
                open: true,
                origin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                severity: 'error',
            }))

            return
        }
        dispatch(reduxSetOrderInformation({
            billingCurrency: newOrder.billingCurrency,
            lazrCadToUsdRate: newOrder.lazrCadToUsdRate,
            clientOrganizationId: newOrder.clientOrganizationId,
            clientOrganizationName: newOrder.clientOrganizationName,
            createdAt: newOrder.createdAt,
            createdByUserId: newOrder.createdByUserId,
            id: newOrder.id,
            lastUpdatedByUserId: newOrder.lastUpdatedByUserId,
            number: newOrder.number,
            rfq: newOrder.rfq,
            status: newOrder.status,
            threePlOrganizationId: newOrder.threePlOrganizationId,
            updatedAt: newOrder.updatedAt,
            lazrUsdToCadRate: newOrder.lazrUsdToCadRate,
            ediOrderId: newOrder.ediOrderId,
            documents: newOrder.documents,
        }))

        localStorage.setItem('marketPlaceStep', MarketPlaceStep.SHIPMENT_INFORMATION.toString())
        localStorage.setItem('marketPlaceOrderId', newOrder.id)
        history.push(`${MARKETPLACE_ROUTES[MarketPlaceStep.MARKETPLACE]}/${newOrder.id}`)

        dispatch(reduxSetMarketPlaceLastCompletedStep(currentStep))

        submitShipmentInformation()
    }

    const handleBooking = (): void => {
        setBooking(true)
        if (!orderId) {
            logger.error(`handleBooking: orderId is empty in page ${currentStep}`)
            setBooking(false)
            resetMarketplace()

            return
        }
        const newOriginAccessorials =
            addServiceLevelAccessorials(serviceAccessorialList, originAccessorials, order.rfq?.selectedQuote ?? null)

        dispatch(reduxSetAddressAccessorials(newOriginAccessorials, AccessorialLocationType.ORIGIN))

        localStorage.setItem('marketPlaceStep', currentStep.toString())

        history.push(`${MARKETPLACE_ROUTES[MarketPlaceStep.COMPLETE_ORDER]}/${orderId}`)
        dispatch(reduxSetMarketPlaceLastCompletedStep(currentStep))
        setBooking(false)
    }

    const handleRequestCustomQuote = async (isCustomQuoteBookedWithCoverage: boolean): Promise<void> => {
        setProcessingCustomQuote(true)

        const hasErrors = validateShipmentInformationStep(true)
        if (hasErrors) {
            setProcessingCustomQuote(false)

            return
        }
        const newStep = MarketPlaceStep.COMPLETE_ORDER

        if (currentStep === MarketPlaceStep.SHIPMENT_INFORMATION) {
            const newOrder = await search(true, isCustomQuoteBookedWithCoverage)
            if (!newOrder) {
                setProcessingCustomQuote(false)

                return
            }

            localStorage.setItem('marketPlaceOrderId', newOrder.id)
            dispatch(reduxSetOrderInformation({
                billingCurrency: newOrder.billingCurrency,
                lazrCadToUsdRate: newOrder.lazrCadToUsdRate,
                clientOrganizationId: newOrder.clientOrganizationId,
                createdAt: newOrder.createdAt,
                createdByUserId: newOrder.createdByUserId,
                id: newOrder.id,
                lastUpdatedByUserId: newOrder.lastUpdatedByUserId,
                number: newOrder.number,
                rfq: {
                    accessorials: newOrder.rfq?.accessorials,
                    handlingUnits: newOrder.rfq?.handlingUnits,
                    quotes: newOrder.rfq?.quotes,
                    createdAt: newOrder.rfq?.createdAt,
                    updatedAt: newOrder.rfq?.updatedAt,
                    lastUpdatedByUserId: newOrder.rfq?.lastUpdatedByUserId,
                    resultsCompleted: newOrder.rfq?.resultsCompleted,
                    resultsSearchInProgress: newOrder.rfq?.resultsSearchInProgress,
                    resultsSearchStartedAt: newOrder.rfq?.resultsSearchStartedAt,
                    reverseMargins: newOrder.rfq?.reverseMargins,
                    shipmentCoverageValue: newOrder.rfq?.shipmentCoverageValue,
                    coverageCommodityType: newOrder.rfq?.coverageCommodityType,
                    isBookedWithCoverage: newOrder.rfq?.isBookedWithCoverage,
                    skidSpots: newOrder.rfq?.skidSpots,
                    linearFeet: newOrder.rfq?.linearFeet,
                    destinationNotifyReceiver: newOrder.rfq?.destinationNotifyReceiver,
                    destinationName: newOrder.rfq?.destinationName,
                    destinationDeliveryDate: newOrder.rfq?.destinationDeliveryDate,
                    destinationPostalCode: newOrder.rfq?.destinationPostalCode,
                    destinationCountry: newOrder.rfq?.destinationCountry,
                    destinationState: newOrder.rfq?.destinationState,
                    destinationCity: newOrder.rfq?.destinationCity,
                    destinationStreetAddress2: newOrder.rfq?.destinationStreetAddress2,
                    destinationStreetAddress: newOrder.rfq?.destinationStreetAddress,
                    destinationContactEmails: newOrder.rfq?.destinationContactEmails,
                    originNotifyShipper: newOrder.rfq?.originNotifyShipper,
                    originName: newOrder.rfq?.originName,
                    originPickupDate: newOrder.rfq?.originPickupDate,
                    originPostalCode: newOrder.rfq?.originPostalCode,
                    originCountry: newOrder.rfq?.originCountry,
                    originState: newOrder.rfq?.originState,
                    originCity: newOrder.rfq?.originCity,
                    originStreetAddress2: newOrder.rfq?.originStreetAddress2,
                    originStreetAddress: newOrder.rfq?.originStreetAddress,
                    originContactEmails: newOrder.rfq?.originContactEmails,
                    selectedQuote: newOrder.rfq?.selectedQuote,
                    equipmentType: newOrder.rfq?.equipmentType,
                    transportType: newOrder.rfq?.transportType,
                    customQuoteRequested: newOrder.rfq?.customQuoteRequested,
                    status: newOrder.rfq?.status,
                    orderId: newOrder.rfq?.orderId,
                    id: newOrder.rfq?.id,
                },
                status: newOrder.status,
                threePlOrganizationId: newOrder.threePlOrganizationId,
                updatedAt: newOrder.updatedAt,
                lazrUsdToCadRate: newOrder.lazrUsdToCadRate,
                ediOrderId: newOrder.ediOrderId,
                documents: newOrder.documents,
            }))

            orderId = newOrder.id
        } else if (currentStep === MarketPlaceStep.MARKETPLACE) {
            const newOriginAccessorials = addServiceLevelAccessorials(
                serviceAccessorialList,
                originAccessorials,
                order.rfq?.selectedQuote ?? null,
            )
            dispatch(reduxSetAddressAccessorials(newOriginAccessorials, AccessorialLocationType.ORIGIN))
            dispatch(reduxSetRfqInformation({ customQuoteRequested: true, selectedQuote: null }))

            localStorage.setItem('marketPlaceOrderId', orderId ?? '')
        }
        dispatch(reduxSetMarketPlaceLastCompletedStep(MarketPlaceStep.MARKETPLACE))
        history.push(`${MARKETPLACE_ROUTES[newStep]}/${orderId ?? ''}`)

        localStorage.setItem('marketPlaceStep', MarketPlaceStep.MARKETPLACE.toString())
        submitShipmentInformation()
    }

    const handleContinue = useCallback(async () => {
        const handleClick = async (callback?: () => void): Promise<void> => {
            callback && callback()

            if (!hasDefaultBillingAddress) {
                setShowBillingAddressModal(true)
                dispatch(reduxSetPromptModalLeave(false))

                return
            }

            if (isExpired) {
                setShowExpiredModal(true)

                return
            }

            dispatch(reduxSetPromptModalLeave(false))
            setSubmitting(true)
            await completeMarketplaceFlow(false, false)
            setSubmitting(false)
        }

        !isPastDateOrder(order, handleClick) && await handleClick()
    }, [
        hasDefaultBillingAddress,
        setShowBillingAddressModal,
        dispatch,
        reduxSetPromptModalLeave,
        isExpired,
        setShowExpiredModal,
        setSubmitting,
        completeMarketplaceFlow,
        isPastDateOrder,
        order,
    ])

    const handleContinueDraft = useCallback(async () => {
        if (isExpired) {
            setShowExpiredModal(true)

            return
        }

        dispatch(reduxSetPromptModalLeave(false))
        setSubmittingDraft(true)
        await completeMarketplaceFlow(true, false)
        setSubmittingDraft(false)
    }, [
        isExpired,
        setShowExpiredModal,
        dispatch,
        reduxSetPromptModalLeave,
        setSubmittingDraft,
        completeMarketplaceFlow,
    ])

    const ProgressSummaryCardHeaderProps = {
        title: (
            <Typography variant='h6'>
                Progress Summary
            </Typography>
        ),
        action: (
            <React.Fragment>
                <ResetOrderButton
                    size='small'
                    startIcon={<StyledIcon icon={[ 'fas', 'trash' ]}/>}
                    onClick={(): void => {
                        setIsConfirmResetOpened(true)
                        if (currentStep === MarketPlaceStep.CONFIRMATION) {
                            dispatch(reduxSetPromptModalLeave(false))
                        }
                    }}
                    aria-label='Reset this order'
                >
                    <ResetOrderLabel variant='caption'>Reset this order</ResetOrderLabel>
                </ResetOrderButton>
                <Modal
                    open={isConfirmResetOpened}
                    modalTitle="Confirm Reset Order"
                    closeButton={false}
                    content={
                        <React.Fragment>
                            <Grid container>
                                <Grid item xs={12} px={6}>
                                    <Typography>
                                        Are you sure you want to reset the current order?
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    buttons={[
                        {
                            title: 'CANCEL',
                            iconProp: CANCEL_ICON,
                            action: (): void => {
                                setIsConfirmResetOpened(false)
                                if (currentStep === MarketPlaceStep.CONFIRMATION) {
                                    dispatch(reduxSetPromptModalLeave(true))
                                }
                            },
                        },
                        {
                            title: 'RESET',
                            iconProp: DELETE_CONFIRM_ICON,
                            className: 'red',
                            action: (): void => {
                                setIsConfirmResetOpened(false)
                                resetMarketplace()
                            },
                        },
                    ]}
                />
            </React.Fragment>
        ),
    }
    const showNextButton = (currentStep <= lastCompletedStep && currentStep < 4 && !isStartingSearch)
        || currentStep === MarketPlaceStep.COMPLETE_ORDER

    const forceCustomQuote = order.rfq?.selectedQuote?.requiresCustomQuote || order.rfq?.selectedQuote?.requiresValidation

    const contentModalLeave =
        (
            <Grid item container xs={12}>
                <Grid item>
                    <ModalTypography px={7} pt={6} pb={2}>
                        You are about to leave but your order has not yet been submitted to the carrier.
                    </ModalTypography>
                    <ModalTypography px={7} pb={6}>
                        Are you sure you want to leave this page without submitting your order?
                    </ModalTypography>
                </Grid>
            </Grid>
        )

    const pastDateModalContent =
        (
            <Grid item container xs={12}>
                <Grid item>
                    <ModalTypography px={7} pt={6} pb={2}>
                        You are about to submit an order with the ready date already passed. The carrier
                        will need to be dispatched with today as the ready date.
                    </ModalTypography>
                    <ModalTypography px={7} pb={6}>
                        Are you sure you wish to use today as the ready date and submit?
                    </ModalTypography>
                </Grid>
            </Grid>
        )

    const startAddressSavingAnimation = async (step: string, type?: AccessorialLocationType) => {
        // Saved
        if (step === 'saved') {
            setIsSavingAddressAnimationStarted(false)
            setIsSavingAddressConfirmationShown(true)
            await sleep(1600)
        }

        // After save
        if (step === 'after') {
            await sleep(1000)
            setIsSavingAddressConfirmationShown(false)
            setIsSavingAddressButtonsDisabled(false)
            setIsConfirmSavingAddressToAddressBookOpened(true)
        }

        // Error
        if (step === 'error') {
            setIsSavingAddressAnimationStarted(false)
            dispatch(reduxSetSnackbar({
                autoHideDuration: 5000,
                message: `Unexpected error! Could not save ${type ? type.toLowerCase() : ''} address!`,
                open: true,
                origin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                severity: 'error',
            }))
        }
    }

    const filteredExistingPickups = order.rfq?.existingPickups?.filter((pickup) =>
        pickup.provider === order.rfq?.selectedQuote?.provider &&
        order.rfq.selectedQuote.carrierId === pickup.carrierId,
    ) || []

    const userCurrency = user?.currency?.code ?? Currency.CAD
    const paperCommInvFeeObject: {
        rawCad : number
        rawUsd: number
    } = getPaperCommInvFee({ currency: (user?.currency) as Currency | null, currencyRateFromCad: cadToUsdRate, provider: order.rfq?.selectedQuote?.provider })
    const paperCommInvFee = userCurrency === Currency.CAD ? paperCommInvFeeObject.rawCad : paperCommInvFeeObject.rawUsd

    let addressValidationModalButtons: ModalProps['buttons'] = [
        {
            title: 'SELECT A DIFFERENT CARRIER',
            iconProp: CANCEL_ICON,
            action: () => {
                setAddressValidationModalOpen(false)
                history.push(`${MARKETPLACE_ROUTES[MarketPlaceStep.MARKETPLACE]}/${orderId ?? ''}`)
            },
        },
        {
            title: 'ACCEPT CHANGES AND SUBMIT YOUR ORDER',
            iconProp: OK_ICON,
            action: async () => {
                if ((carrierSuggestedAddresses?.originAddresses && !selectedValidatedOriginAddress) ||
                    (carrierSuggestedAddresses?.destinationAddresses && !selectedValidatedDestinationAddress)) {
                    logger.error('Please select an address from one of the suggested carrier addresses for origin and destination')
                    dispatch(reduxSetSnackbar({
                        autoHideDuration: 5000,
                        message: 'Please select an address from one of the suggested carrier addresses for origin and destination.',
                        open: true,
                        origin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        severity: 'error',
                    }))

                    return
                }
                setAddressValidationModalOpen(false)

                setSubmitting(true)
                await completeMarketplaceFlow(false, true)
                setSubmitting(false)
            },
        },
    ]

    if (provider === Provider.UPS) {
        addressValidationModalButtons = addressValidationModalButtons.concat([
            {
                title: 'PROCEED WITH MY ADDRESS INPUT',
                iconProp: OK_ICON,
                note: <><RedStar />Please note that ${getWrongAddressSurcharge({ currency: user?.currency?.code ?? defaultUserCurrency, currencyRateFromCad: cadToUsdRate, provider })} fee per box may apply if the address is incorrect.</>,
                action: async () => {
                    setAddressValidationModalOpen(false)
                    await completeMarketplaceFlow(false, true, true)
                },
            },
        ])
    }


    return <StickyContainer>
        <Prompt
            when={!user}
            message={(location, action): boolean => {
                if (action === 'POP') {
                    return true
                }

                let isAllowedRoute = ALLOWED_PUBLIC_MARKETPLACE_ROUTES.some((route) => location.pathname.startsWith(route))
                isAllowedRoute = isAllowedRoute || location.pathname === homeRoute.path
                if (!isAllowedRoute) {
                    setRegisterModalAction(RegisterModalAction.REGISTER)
                    setShowRegisterModal(true)
                }

                return isAllowedRoute
            }}
        />
        <Card pt={1} pb={3} px={isXs ? 1 : 6}>
            <Hidden lgDown>
                <CardHeader
                    {...ProgressSummaryCardHeaderProps}
                />
            </Hidden>
            <Hidden xlUp>
                <ArrowIconContainer
                    onClick={toggle}
                    $configuration={isTwoButtonConfiguration ? 'twoButton' : 'oneButton'}
                >
                    <ArrowIcon
                        icon={[ 'fal', 'angle-left' ]}
                        rotation={isCollapsed ? 90 : 270}
                    />
                </ArrowIconContainer>
            </Hidden>
            <Prompt
                when={promptModalLeave && !showExpiredModal}
                message={(location, action): boolean => {
                    if (currentStep !== MarketPlaceStep.CONFIRMATION) {
                        return true
                    }

                    setModalLeaveDisplayed(true)
                    setLocationPathName(location.pathname)
                    dispatch(reduxSetPromptModalLeave(false))

                    return false
                }}
            />
            <CardContent px={isXs ? 3 : ''}>
                <Grid container justifyContent='space-between' spacing={4} alignItems='center'>
                    <Hidden xlUp>
                        <Grid item xs={12}>
                            <Collapse in={!isCollapsed}>
                                <ScrollBar>
                                    <CardHeader pl={0} pr={0} pt={0}
                                        {...ProgressSummaryCardHeaderProps}
                                    />
                                    <Grid container justifyContent='space-between' spacing={8} direction='column'>
                                        <Grid item xs sm>
                                            <ProgressSummaryInformation sectionA={sectionA} sectionB={sectionB}/>
                                        </Grid>
                                        <Hidden mdUp>
                                            <StepperGrid item xs sm>
                                                <ProgressSummaryStepper
                                                    activeStep={currentStep}
                                                    lastCompletedStep={lastCompletedStep}
                                                    orderId={orderId}
                                                />
                                            </StepperGrid>
                                        </Hidden>
                                    </Grid>
                                </ScrollBar>
                                <Divider mt={6} mb={5}/>
                            </Collapse>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent='space-between'
                            direction='row'
                            spacing={isXs ? 1 : 2}
                            alignItems={isTwoButtonConfiguration ? 'flex-end' : 'center'}
                        >
                            <LeftSideGrid item container justify-content='flex-start' xs sm={3} md={2} lg={1} xl>
                                <BackButton
                                    onClick={(): void => {
                                        setBackButtonIconProp(SPINNER_ICON)
                                        const previousStep: MarketPlaceStep = currentStep - 1
                                        history.push(`${MARKETPLACE_ROUTES[previousStep]}/${orderId ?? ''}`)
                                    }}
                                    variant='contained'
                                    startIcon={
                                        <FontAwesomeIcon
                                            icon={backButtonIconProp}
                                            pulse={backButtonIconProp === SPINNER_ICON}
                                            className="large"
                                        />
                                    }
                                    fullWidth={isXs}
                                    className={currentStep === MarketPlaceStep.SHIPMENT_INFORMATION ? 'hidden' : 'visible'}
                                >
                                    Back
                                </BackButton>
                            </LeftSideGrid>
                            <Hidden lgDown>
                                <Grid item xl>
                                    <ProgressSummaryInformation sectionA={sectionA} sectionB={sectionB}/>
                                </Grid>
                            </Hidden>
                            <Hidden smDown>
                                <Grid item md={6} lg={8} xl>
                                    <ProgressSummaryStepper
                                        activeStep={currentStep}
                                        lastCompletedStep={lastCompletedStep}
                                        orderId={orderId}
                                    />
                                </Grid>
                            </Hidden>
                            <RightSideGrid item xs sm={6} md={3} xl>
                                <Grid container justifyContent='space-between' direction='column' spacing={1}>
                                    {showNextButton &&
                                        <Grid item container justifyContent='flex-end'>
                                            <DefaultButton
                                                disabled={isValidatingCompleteOrderStep || isChangedStep}
                                                variant='contained'
                                                endIcon={
                                                    <FontAwesomeIcon
                                                        icon={nextButtonIconProp}
                                                        pulse={nextButtonIconProp === SPINNER_ICON}
                                                        className="large"
                                                    />
                                                }
                                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                                onClick={goToNextStep}
                                            >
                                                Next
                                            </DefaultButton>
                                        </Grid>
                                    }
                                    {!showCustomQuote
                                        && currentStep === MarketPlaceStep.SHIPMENT_INFORMATION
                                        && lastCompletedStep < MarketPlaceStep.SHIPMENT_INFORMATION
                                        &&
                                        <Grid item container justifyContent='flex-end'>
                                            <DefaultButton
                                                disabled={isLazrItButtonDisabled}
                                                variant='contained'
                                                endIcon={isStartingSearch ?
                                                    <FontAwesomeIcon icon={SPINNER_ICON} pulse />
                                                    :
                                                    <IconLogo />
                                                }
                                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                                onClick={async (): Promise<void> => {
                                                    const hasErrors = validateShipmentInformationStep(true)
                                                    if (hasErrors) {
                                                        return
                                                    }
                                                    if (moment(order.rfq?.originPickupDate).isSame(moment(), 'day') &&
                                                        moment().isSameOrAfter(moment().hour(13), 'hour') && !isMailing) {
                                                        setIsSameDayPickupDialogOpen(true)
                                                    } else {
                                                        await handleLazrIt()
                                                    }
                                                }}
                                            >
                                                {isStartingSearch ? 'STARTING SEARCH' : 'LAZR IT!'}
                                            </DefaultButton>
                                        </Grid>
                                    }
                                    {currentStep === MarketPlaceStep.MARKETPLACE && MarketPlaceStep.MARKETPLACE > lastCompletedStep
                                        &&
                                        <Grid item container justifyContent='flex-end'>
                                            <Tooltip
                                                placement='top'
                                                title={forceCustomQuote ? 'Price invalid. A custom quote is required.' : ''}
                                            >
                                                <div>
                                                    <DefaultButton
                                                        variant='contained'
                                                        onClick={handleBooking}
                                                        disabled={
                                                            !order.rfq?.selectedQuote ||
                                                            forceCustomQuote ||
                                                            isBooking
                                                        }
                                                    >
                                                        {isBookedWithCoverage ? 'Book with coverage' : 'Book'}
                                                    </DefaultButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                    }
                                    {((currentStep === MarketPlaceStep.SHIPMENT_INFORMATION
                                            && lastCompletedStep < MarketPlaceStep.SHIPMENT_INFORMATION
                                            && showCustomQuote
                                    ) || (currentStep === MarketPlaceStep.MARKETPLACE && currentStep > lastCompletedStep))
                                        &&
                                        <Grid item container justifyContent='flex-end'>
                                            {isMailing && currentStep > MarketPlaceStep.SHIPMENT_INFORMATION
                                                ?
                                                <WarningButton
                                                    disabled={isCustomQuoteDisabled}
                                                    variant='contained'
                                                    onClick={() => {
                                                        dispatch(reduxSetSelectedQuote(null))
                                                        dispatch(reduxSetIsBookedWithCoverage(null))
                                                        // @ts-ignore
                                                        dispatch(reduxSetMarketPlaceLastCompletedStep(-1))
                                                        resetMarketplaceRoute()
                                                    }}
                                                >
                                                    Change Shipment Info
                                                </WarningButton>
                                                :
                                                <Tooltip
                                                    placement='top'
                                                    title={(isMailing && showCustomQuote) ?
                                                        'Cannot request a custom quote for parcel shipments' : ''}
                                                >
                                                    <div>
                                                        <WarningButton
                                                            disabled={isMailing || isCustomQuoteDisabled}
                                                            variant='contained'
                                                            onClick={() => setIsConfirmCoverageOpened(true)}
                                                        >
                                                            Request&nbsp;custom&nbsp;quote
                                                        </WarningButton>
                                                    </div>
                                                </Tooltip>
                                            }
                                        </Grid>
                                    }
                                    {currentStep === MarketPlaceStep.CONFIRMATION && user && user.permissions.submitOrder
                                        &&
                                        <Tooltip
                                            placement='top'
                                            title={isOrderNonSubmittable
                                                ?
                                                submitTooltip
                                                : ''
                                            }
                                        >
                                            <Grid item>
                                                {order.rfq?.customQuoteRequested ?
                                                    <WarningButton
                                                        disabled={isOrderNonSubmittable || isSubmitting || isSubmittingDraft}
                                                        variant='contained'
                                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                                        onClick={async (): Promise<void> => {
                                                            dispatch(reduxSetPromptModalLeave(false))
                                                            if (!hasDefaultBillingAddress) {
                                                                setShowBillingAddressModal(true)

                                                                return
                                                            }
                                                            setSubmitting(true)
                                                            await completeMarketplaceFlow(false, false)
                                                            setSubmitting(false)
                                                        }}
                                                        endIcon={isSubmitting && <FontAwesomeIcon icon={SPINNER_ICON} pulse/>}
                                                    >
                                                        {isSubmitting ? 'Submitting...' : 'Request custom quote'}
                                                    </WarningButton>
                                                    :
                                                    <DefaultButton
                                                        disabled={isOrderNonSubmittable || isSubmitting || isSubmittingDraft}
                                                        variant='contained'
                                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                                        onClick={async (): Promise<void> => {
                                                            setIsSaveAsDraft(false)
                                                            /* Disclaimer Dialog */
                                                            if (
                                                                isInternationalOrder(
                                                                    order.rfq?.originCountry?.code, order.rfq?.destinationCountry?.code,
                                                                )
                                                                && !isCaUsPrViLane(
                                                                    order.rfq?.originCountry?.code, order.rfq?.destinationCountry?.code,
                                                                )
                                                            ) {
                                                                setShowCustomsDocumentsDisclaimerDialog(true)

                                                                return
                                                            }

                                                            await handleContinue()
                                                        }}
                                                        endIcon={isSubmitting && <FontAwesomeIcon icon={SPINNER_ICON} pulse/>}
                                                    >
                                                        Submit Order
                                                    </DefaultButton>
                                                }
                                            </Grid>
                                        </Tooltip>
                                    }
                                    {currentStep === MarketPlaceStep.CONFIRMATION
                                        &&
                                        <Grid item>
                                            <SaveAsDraftButton
                                                disabled={isSubmitting || isSubmittingDraft}
                                                variant='contained'
                                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                                onClick={async (): Promise<void> => {
                                                    setIsSaveAsDraft(true)
                                                    /* Disclaimer Dialog */
                                                    if (
                                                        isInternationalOrder(
                                                            order.rfq?.originCountry?.code, order.rfq?.destinationCountry?.code,
                                                        )
                                                        && !isCaUsPrViLane(
                                                            order.rfq?.originCountry?.code, order.rfq?.destinationCountry?.code,
                                                        )
                                                    ) {
                                                        setShowCustomsDocumentsDisclaimerDialog(true)

                                                        return
                                                    }

                                                    await handleContinueDraft()
                                                }}
                                                endIcon={isSubmittingDraft && <FontAwesomeIcon icon={SPINNER_ICON} pulse/>}
                                            >
                                                Save as Draft
                                            </SaveAsDraftButton>
                                        </Grid>
                                    }

                                </Grid>
                            </RightSideGrid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Modal
            maxWidth={700}
            onClose={(): void => {
                setModalLeaveDisplayed(false)
                setBackButtonIconProp(BACK_ICON)
                dispatch(reduxSetPromptModalLeave(true))
            }}
            modalTitle='Order Pending Submission'
            closeButton={true}
            open={modalLeaveDisplayed}
            content={contentModalLeave}
            buttons={[
                {
                    title: 'Leave page',
                    action: (): void => {
                        setModalLeaveDisplayed(false)
                        history.push(locationPathName)
                    },
                },
                {
                    title: 'Save draft',
                    disabled: isSubmitting || isSubmittingDraft,
                    action: async () => {
                        setModalLeaveDisplayed(false)
                        setSubmittingDraft(true)
                        await completeMarketplaceFlow(true, false)
                        setSubmittingDraft(false)
                    },
                },
                {
                    title: order.rfq?.customQuoteRequested ? 'Request custom quote' : 'Submit order',
                    disabled: isOrderNonSubmittable || isSubmitting || isSubmittingDraft || !user?.permissions.submitOrder,
                    action: async () => {
                        setModalLeaveDisplayed(false)
                        setSubmitting(true)
                        await completeMarketplaceFlow(false, false)
                        setSubmitting(false)
                    },
                    className: order.rfq?.customQuoteRequested ? 'noMargin' : '',
                },
            ]}
        />
        <Modal
            open={openPastDateOrderModal}
            modalTitle={'Ready Date Already Passed'}
            closeButton={true}
            onClose={() => setOpenPastDateOrderModal(!openPastDateOrderModal)}
            content={pastDateModalContent}
            buttons={[
                {
                    title: 'CANCEL',
                    action: (): void => setOpenPastDateOrderModal(!openPastDateOrderModal),
                },
                {
                    title: 'Submit Order',
                    disabled: false,
                    className: 'string',
                    action: () => submitPastDateOrder && submitPastDateOrder(() => setOpenPastDateOrderModal(!openPastDateOrderModal)),
                },
            ]}
        />
        <Modal
            open={showExpiredModal}
            onClose={(): void => {
                setShowExpiredModal(false)
            }}
            maxWidth={550}
            modalTitle="Quote expired"
            closeButton={true}
            content={
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} px={6}>
                            <Typography>
                                The quote on this order has expired on <b>{shownExpirationDate}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            buttons={[
                {
                    title: 'Reset order',
                    iconProp: DELETE_CONFIRM_ICON,
                    action: (): void => {
                        setShowExpiredModal(false)
                        resetMarketplace()
                    },
                },
                {
                    title: 'Reset prices',
                    iconProp: SETTINGS_ICON,
                    action: (): void => {
                        setShowExpiredModal(false)
                        dispatch(reduxSetSelectedQuote(null))
                        dispatch(reduxSetIsBookedWithCoverage(null))
                        // @ts-ignore
                        dispatch(reduxSetMarketPlaceLastCompletedStep(-1))
                        dispatch(reduxSetMarketPlaceStep(MarketPlaceStep.SHIPMENT_INFORMATION))
                        resetMarketplaceRoute()
                    },
                },
            ]}
        />
        <Modal
            open={isSameDayPickupDialogOpen}
            maxWidth={650}
            modalTitle="Confirm Same Day Pickup"
            closeButton={false}
            content={
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} px={6}>
                            <Typography>
                                Please note that orders for today after 2:00pm may be picked up the following day and will require
                                a custom quote from an agent. For any special request regarding this order, please contact us directly.
                            </Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            buttons={[
                {
                    title: 'CANCEL',
                    iconProp: CANCEL_ICON,
                    action: (): void => {
                        setIsSameDayPickupDialogOpen(false)
                    },
                },
                {
                    title: 'REQUEST CUSTOM QUOTE',
                    disabled: !user?.permissions.submitOrder,
                    iconProp: SETTINGS_ICON,
                    action: async () => {
                        setIsSameDayPickupDialogOpen(false)
                        await sleep(200).then(() => {
                            setIsConfirmCoverageOpened(true)
                        })
                    },
                },
                {
                    title: 'LAZR IT!',
                    iconProp: OK_ICON,
                    action: async () => {
                        setIsSameDayPickupDialogOpen(false)
                        await handleLazrIt()
                    },
                },
            ]}
        />
        <Modal
            open={isConfirmCoverageOpened}
            modalTitle="Confirm Custom Quote Coverage"
            closeButton={false}
            content={
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} px={6}>
                            <Typography>
                                Would you like to request Lazr coverage on your custom quote?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} pt={4} px={6}>
                            <TextField
                                id='valueCoverage'
                                variant='outlined'
                                type='number'
                                inputMode='numeric'
                                fullWidth
                                size='small'
                                label='Shipment value coverage'
                                value={shipmentCoverageValue || ''}
                                autoComplete='new-password'
                                onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>): void => {
                                        dispatch(
                                            reduxSetShipmentCoverageValue(isNaN(event.target.valueAsNumber) ? 0 :
                                                event.target.valueAsNumber),
                                        )
                                    }
                                }
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">CAD</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} pt={4} px={6}>
                            <Autocomplete
                                id='commodityType'
                                options={getCommodityTypes()}
                                getOptionLabel={(option) => option.name}
                                onChange={(_event, newCommodityType): void => {
                                    dispatch(reduxSetCommodityType(newCommodityType))
                                }}
                                handleHomeEndKeys
                                autoHighlight
                                autoSelect
                                selectOnFocus
                                fullWidth
                                size='small'
                                value={commodityType || null}
                                renderInput={(params): React.ReactNode => (
                                    <TextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                        variant='outlined'
                                        label="Commodity type"
                                        helperText={
                                            <a href='/lazrCoverageExclusionDocument.pdf'
                                                target='_blank' rel="noreferrer">Coverage exclusions</a>
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} pt={4} px={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color='primary'
                                        checked={isCoverageExclusionsAccepted}
                                        onChange={
                                            (event: React.ChangeEvent<HTMLInputElement>): void => {
                                                dispatch(reduxSetIsCoverageExclusionsAccepted(event.target.checked))
                                            }
                                        }
                                    />
                                }
                                label='I accept the exclusions'
                                labelPlacement='end'
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            buttons={[
                {
                    title: 'CANCEL',
                    iconProp: CANCEL_ICON,
                    action: () => {
                        setIsConfirmCoverageOpened(false)
                    },
                },
                {
                    title: 'NO',
                    iconProp: NO_ICON,
                    action: async () => {
                        setIsConfirmCoverageOpened(false)
                        dispatch(reduxSetIsBookedWithCoverage(!!shipmentCoverageValue && !!commodityType))
                        await handleRequestCustomQuote(!!shipmentCoverageValue && !!commodityType)
                    },
                },
                {
                    title: 'YES',
                    disabled: !(!!shipmentCoverageValue && !!commodityType && isCoverageExclusionsAccepted),
                    iconProp: OK_ICON,
                    action: async () => {
                        setIsConfirmCoverageOpened(false)
                        dispatch(reduxSetIsBookedWithCoverage(!!shipmentCoverageValue && !!commodityType))
                        await handleRequestCustomQuote(!!shipmentCoverageValue && !!commodityType)
                    },
                },
            ]}
        />

        <Modal
            open={isAddressValidationModalOpen}
            modalTitle="Confirm Addresses"
            maximumWidth={'md'}
            maxWidth={800}
            closeButton
            onClose={() => {
                setAddressValidationModalOpen(false)
            }}
            content={
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} px={6}>
                            <Typography>
                                The carrier you selected requires further confirmation of your addresses.
                            </Typography>
                        </Grid>
                        {carrierSuggestedAddresses?.originAddresses &&
                            <AddressValidation
                                title={'Origin'}
                                pl={6}
                                carrierAddresses={carrierSuggestedAddresses.originAddresses}
                                userAddress={{
                                    streetAddress: order.rfq?.originStreetAddress ?? '',
                                    streetAddress2: order.rfq?.originStreetAddress2 || null,
                                    streetAddress3: order.rfq?.originStreetAddress3 || null,
                                    postalCode: order.rfq?.originPostalCode ?? '',
                                    city: order.rfq?.originCity ?? '',
                                    state: order.rfq?.originState ?? '',
                                    country: order.rfq?.originCountry?.code ?? '' as CountryEnum,
                                }}
                            />
                        }
                        {carrierSuggestedAddresses?.destinationAddresses &&
                            <AddressValidation
                                title={'Destination'}
                                pl={6}
                                carrierAddresses={carrierSuggestedAddresses.destinationAddresses}
                                userAddress={{
                                    streetAddress: order.rfq?.destinationStreetAddress ?? '',
                                    streetAddress2: order.rfq?.destinationStreetAddress2 || null,
                                    streetAddress3: order.rfq?.destinationStreetAddress3 || null,
                                    postalCode: order.rfq?.destinationPostalCode ?? '',
                                    city: order.rfq?.destinationCity ?? '',
                                    state: order.rfq?.destinationState ?? '',
                                    country: order.rfq?.destinationCountry?.code ?? '' as CountryEnum,
                                }}
                            />
                        }
                    </Grid>
                </React.Fragment>
            }
            buttons={addressValidationModalButtons}
        />
        <Modal
            open={showBillingAddressModal}
            onClose={(): void => {
                // no-op
            }}
            maxWidth={650}
            modalTitle="Create a default billing address"
            closeButton={false}
            content={
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} px={6}>
                            {isOrganizationAdmin ?
                                <Typography variant="body1">
                                    Your address information need to be complete to submit an order,
                                    please provide a default billing address.
                                </Typography> :
                                <Typography variant="body1">
                                    Your organization&apos;s address information need to be complete to submit an order.
                                    Please ask you administrator to create a default billing address.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            buttons={[
                {
                    title: 'CANCEL',
                    iconProp: CANCEL_ICON,
                    action: (): void => {
                        setShowBillingAddressModal(false)
                    },
                },
                {
                    title: 'SET BILLING ADDRESS',
                    iconProp: SETTINGS_ICON,
                    action: (): void => {
                        setShowBillingAddressModal(false)
                        history.push(addressBookRoute.path)
                    },
                },
            ]}
        />
        <RegisterModal
            order={order}
            showRegisterModal={showRegisterModal}
            setShowRegisterModal={setShowRegisterModal}
            action={registerModalAction}
            orderId={orderId}
        />
        <SignInModal showSignInModal={showSignInModal}/>
        <RescheduledPickupModal onClose={() => {
            dispatch(setNewPickupDate(''))
            dispatch(reduxSetMarketPlaceLastCompletedStep(currentStep))
            cleanupMarketplace()
        }
        }/>
        <Modal
            open={isConfirmSavingAddressToAddressBookOpened}
            onClose={isSavingAddressButtonsDisabled ? undefined : (): void => {
                setIsConfirmSavingAddressToAddressBookOpened(false)
            }}
            maxWidth={650}
            modalTitle={addressBookAddressesToSave[0]?.title || ''}
            closeButton={!isSavingAddressButtonsDisabled}
            content={
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} px={6}>
                            <Typography>
                                {addressBookAddressesToSave[0]?.message || ''}
                            </Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            buttons={addressBookAddressesToSave[0]?.address ? [
                {
                    title: 'CANCEL',
                    iconProp: CANCEL_ICON,
                    disabled: isSavingAddressButtonsDisabled,
                    action: () => {
                        setIsConfirmSavingAddressToAddressBookOpened(false)
                    },
                },
                {
                    title: 'NO',
                    iconProp: NO_ICON,
                    disabled: isSavingAddressButtonsDisabled,
                    action: () => {
                        const isDoneSavingAddresses = addressBookAddressesToSave.length < 2
                        setIsConfirmSavingAddressToAddressBookOpened(!isDoneSavingAddresses)
                        setAddressBookAddressesToSave(addressBookAddressesToSave.slice(1))
                        if (isDoneSavingAddresses) {
                            afterAddressSave()
                        }
                    },
                },
                {
                    title: isSavingAddressConfirmationShown ? 'SAVED' : 'YES',
                    iconProp: isSavingAddressAnimationStarted ? SPINNER_ICON : OK_ICON,
                    disabled: isSavingAddressButtonsDisabled,
                    className: isSavingAddressConfirmationShown ? 'green' : '',
                    action: async () => {
                        // Front-end animation and save address
                        setIsSavingAddressAnimationStarted(true)
                        setIsSavingAddressButtonsDisabled(true)
                        try {
                            await saveAddressToAddressBook(
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                addressBookAddressesToSave[0].address!,
                                addressBookAddressesToSave[0].type,
                            )
                            await startAddressSavingAnimation('saved')
                        } catch (e: any) {
                            console.error(e)
                            await startAddressSavingAnimation('error', addressBookAddressesToSave[0].type)
                            validateCompleteOrder()
                        }
                        // Check if we're done saving both addresses
                        const isDoneSavingAddresses = addressBookAddressesToSave.length < 2
                        setIsConfirmSavingAddressToAddressBookOpened(!isDoneSavingAddresses)
                        setAddressBookAddressesToSave(addressBookAddressesToSave.slice(1))

                        // Close modal and delay the next one
                        setIsConfirmSavingAddressToAddressBookOpened(false)
                        await startAddressSavingAnimation('after')

                        // Proceed with the order
                        if (isDoneSavingAddresses) {
                            afterAddressSave()
                        }
                    },
                },
            ] : [ {
                title: 'OK',
                iconProp: OK_ICON,
                className: isSavingAddressConfirmationShown ? 'green' : '',
                action: () => {
                    const isDoneSavingAddresses = addressBookAddressesToSave.length < 2
                    setIsConfirmSavingAddressToAddressBookOpened(!isDoneSavingAddresses)
                    setAddressBookAddressesToSave(addressBookAddressesToSave.slice(1))
                    if (isDoneSavingAddresses) {
                        afterAddressSave()
                    }
                },
            } ]}
        />
        <Modal
            open={isSchedulePickupOpened}
            onClose={(): void => {
                setIsSchedulePickupOpened(false)
            }}
            maxWidth={650}
            modalTitle={filteredExistingPickups.length > 0 ? 'Existing Pickup(s) Detected' : 'No Pickups Detected'}
            closeButton
            content={
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} px={6}>
                            {filteredExistingPickups.length > 0 ?
                                <>
                                    <Typography>
                                        Lazr has detected existing pickup request(s) for&nbsp;
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {moment(order.rfq?.originPickupDate).format('ddd, MMM D, YYYY')}
                                        </span>.
                                    </Typography>
                                    <Typography>&nbsp;</Typography>
                                    <Typography>
                                        Existing pickup request numbers:
                                        {filteredExistingPickups.map((pickup, index) =>
                                            <Typography key={index}>- {pickup.pickupNumber}</Typography>,
                                        )}
                                    </Typography>
                                    <Typography>&nbsp;</Typography>
                                    <Typography>
                                        To ensure the best possible handling of your shipments, please specify whether a
                                        new pickup request should be made to the carrier or if this shipment will be part
                                        of one of the above existing pickup requests.
                                    </Typography>
                                    <Typography>&nbsp;</Typography>
                                    <Typography>
                                        All Canadian pickup requests for UPS Ground on call have been temporarily suspended effective Wednesday, December 4th.
                                    </Typography>
                                </>
                                :
                                <>
                                    <Typography>
                                        Lazr does not currently have any existing pickup requests on record for&nbsp;
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {moment(order.rfq?.originPickupDate).format('ddd, MMM D, YYYY')}
                                        </span>.
                                    </Typography>
                                    <Typography>&nbsp;</Typography>
                                    <Typography>
                                        To ensure the best possible handling of your shipments, please specify whether a
                                        new pickup request should be made to the carrier or if this shipment will be part
                                        of an existing pickup that is not currently in Lazr&apos;s records.
                                    </Typography>
                                    <Typography>&nbsp;</Typography>
                                    <Typography>
                                        All Canadian pickup requests for UPS Ground on call have been temporarily suspended effective Wednesday, December 4th.
                                    </Typography>
                                </>
                            }
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            buttons={[
                {
                    title: 'CANCEL',
                    action: () => {
                        setIsSchedulePickupOpened(false)
                    },
                },
                {
                    title: 'NEW PICKUP',
                    action: () => {
                        dispatch(reduxSetSchedulePickup(true))
                        setIsSchedulePickupOpened(false)
                        if (!isEtdAlwaysEnabled && providerSupportsEtd && internationalOrder) {
                            setShowEtdModal(true)
                        } else {
                            proceedCompleteOrder()
                        }
                    },
                    disabled: !isPickupAvailable
                },
                {
                    title: 'EXISTING PICKUP',
                    action: () => {
                        dispatch(reduxSetSchedulePickup(false))
                        setIsSchedulePickupOpened(false)
                        if (!isEtdAlwaysEnabled && providerSupportsEtd && internationalOrder) {
                            setShowEtdModal(true)
                        } else {
                            proceedCompleteOrder()
                        }
                    },
                },
            ]
            }
        />
        <Modal
            open={showEtdModal}
            onClose={(): void => {
                setShowEtdModal(false)
            }}
            maxWidth={700}
            modalTitle="Electronic Trade Documents (ETD)"
            closeButton={true}
            content={
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} px={6}>
                            <ModalTypography>
                                Would you like to enable ETD for this order so that your customs documents will be
                                transmitted electronically to customs?
                            </ModalTypography>
                            {etdModalProps.documentList && etdModalProps.documentList.length > 0 && <>
                                <ModalTypography mt={4}>
                                    Please note that the following customs documents cannot be transmitted
                                    electronically to {etdModalProps.carrierName} and will require you to attach them to your
                                    shipment normally:
                                </ModalTypography>
                                <ModalTypography>
                                    <ul>
                                        {etdModalProps.documentList?.map((document, index) => (
                                            <li key={`etdDoc_${index}`}>{document}</li>
                                        ))}
                                    </ul>
                                </ModalTypography>
                            </>}
                            <ModalTypography mt={4}>
                                It is your responsibility, as the shipper, to provide all necessary
                                customs documents in a timely manner before the shipment arrives at
                                customs to avoid any potential delays or additional fees.
                            </ModalTypography>
                            {!etdModalProps.supportDeletion && <ModalTypography mt={4}>
                                Please ensure that the information submitted via ETD is accurate as it
                                cannot be deleted once sent electronically to customs.
                            </ModalTypography>}
                            <ModalTypography mt={4}>
                                While opting for ETD is encouraged for its potential efficiency in processing
                                documents, we kindly request that you attach the documents physically as a
                                fallback measure, ensuring comprehensive documentation in the event of any
                                unforeseen electronic submission challenges.
                            </ModalTypography>
                            {paperCommInvFee > 0 && <ModalTypography mt={4}>
                                If you proceed without the ETD, a "Paper Commercial Invoice Surcharge" of {userCurrency === Currency.USD ? 'US$' : '$'}{paperCommInvFee} will be added to your bill.
                            </ModalTypography>}
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            buttons={[
                {
                    title: 'PROCEED WITHOUT ETD',
                    action: (): void => {
                        setShowEtdModal(false)
                        dispatch(reduxSetEtdEnabled(false))
                        proceedCompleteOrder()
                    },
                },
                {
                    title: 'PROCEED WITH ETD',
                    action: (): void => {
                        setShowEtdModal(false)
                        dispatch(reduxSetEtdEnabled(true))
                        proceedCompleteOrder()
                    },
                },
            ]}
        />
        <Modal
            open={showCustomsDocumentsDisclaimerDialog}
            maxWidth={650}
            modalTitle="Customs Documents Disclaimer"
            closeButton={false}
            content={
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} px={6}>
                            <Typography variant="body1">
                                For international shipments imported or exported from North America, you agree to be responsible
                                for providing the correct customs documents for your shipment.
                            </Typography>
                            <Typography>&nbsp;</Typography>
                            <Typography>
                                Incorrect documentation supplied to customs could result in delays or additional charges to
                                your shipment.
                            </Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            buttons={[
                {
                    title: 'CANCEL',
                    action: (): void => {
                        setShowCustomsDocumentsDisclaimerDialog(false)
                    },
                },
                {
                    title: 'I WILL TAKE RESPONSIBILITY',
                    action: (): void => {
                        setShowCustomsDocumentsDisclaimerDialog(false)
                        isSaveAsDraft ? void handleContinueDraft() : void handleContinue()
                    },
                },
            ]}
        />
    </StickyContainer>
}

export type Props = {}

export default ProgressSummary


const validatePastDate = (originPickupDate?: string | null): boolean =>
    moment(originPickupDate).endOf('day').isBefore(moment())

const getProviderSpecificCompleteOrderStepValidator =
    (provider: Provider | undefined, transportType: TransportType | undefined) => {
        switch (provider) {
            case Provider.CANADA_POST:
                return canadaPostCompleteOrderStepValidator

            case Provider.PUROLATOR:
                return purolatorCompleteOrderStepValidator

            case Provider.FEDEX:
                if (isMailingService(transportType)) {
                    return fedexMailCompleteOrderStepValidator
                }

                return fedexFreightCompleteOrderStepValidator

            case Provider.CANPAR:
                return canparCompleteOrderStepValidator

            case Provider.UPS:
                return upsCompleteOrderStepValidator

            case Provider.ROSE_ROCKET:
                return roseRocketCompleteOrderStepValidator

            case Provider.PROJECT_44:
                return p44CompleteOrderStepValidator

            case Provider.DHL:
                return dhlCompleteOrderStepValidator

            default:
                return completeOrderStepValidator
        }
    }
