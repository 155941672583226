/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import { Strong } from '../../../AppLayout.styled'

export default {
    ['New Version Available']: 'New Version Available',
    ['Reload Lazr at your convenience for the latest version.']: 'Reload Lazr at your convenience for the latest version.',
    ['Reload']: 'Reload',
    Attention: 'Attention',
    message: () => (
        <>
            <p>
                <Strong>SERVICE AVAILABILITY UPDATE – CANADA POST STRIKE</Strong>
            </p>
            <p>
                <Strong>UPS:</Strong> Domestic Ground Shipment on-call pickup suspended until further notice. Daily pickups unaffected.
                Express services unaffected.
            </p>
            <p>
                <Strong>PUROLATOR:</Strong> Daily limit on all services in place. Service might not be available.
            </p>
            <p>
                <Strong>GLS CANADA:</Strong> All services are unavailable due to carrier being over capacity.
            </p>
        </>
    ),
}
