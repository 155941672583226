import React from 'react'

import BaseCarrierLogo from './BaseCarrierLogo'

import EighteenWheelsLogo from './CarrierLogos/18Wheels/Logo'
import AbfFreightLogo from './CarrierLogos/AbfFreight/Logo'
import AndMoreLogo from './CarrierLogos/AndMore/Logo'
import ApexLogo from './CarrierLogos/Apex/Logo'
import BourretLogo from './CarrierLogos/Bourret/Logo'
import CanadaPostLogo from './CarrierLogos/CanadaPost/Logo'
import CanparLogo from './CarrierLogos/Canpar/Logo'
import CascadesLogo from './CarrierLogos/Cascades/Logo'
import CctCanada from './CarrierLogos/CctCanada/Logo'
import Cti3Logo from './CarrierLogos/Cti3/Logo'
import ChrLogo from './CarrierLogos/Chr/Logo'
import ConwayLogo from './CarrierLogos/Conway/Logo'
import DayAndRossLogo from './CarrierLogos/DayAndRoss/Logo'
import DhlLogo from './CarrierLogos/Dhl/Logo'
import EchoLogo from './CarrierLogos/Echo/Logo'
import EstesLogo from './CarrierLogos/Estes/Logo'
import ExprexLogo from './CarrierLogos/Exprex/Logo'
import FastfrateLogo from './CarrierLogos/Fastfrate/Logo'
import FedexLogo from './CarrierLogos/Fedex/Logo'
import ForwardAirLogo from './CarrierLogos/ForwardAir/Logo'
import FrontlineLogo from './CarrierLogos/Frontline/Logo'
import GariepyLogo from './CarrierLogos/Gariepy/Logo'
import GlsCanadaLogo from './CarrierLogos/GlsCanada/Logo'
import GuilbaultLogo from './CarrierLogos/Guilbault/Logo'
import HollandLogo from './CarrierLogos/Holland/Logo'
import IntegratedTransportationSolutionLogo from './CarrierLogos/IntegratedTransportationSolution/Logo'
import InterNordLogo from './CarrierLogos/InterNord/Logo'
import JaysTransportationLogo from './CarrierLogos/JaysTransportation/Logo'
import JpsLogistiqueLogo from './CarrierLogos/JpsLogistique/Logo'
import LabrancheLogo from './CarrierLogos/Labranche/Logo'
import LazrLogo from './CarrierLogos/Lazr/Logo'
import ManitoulinLogo from './CarrierLogos/Manitoulin/Logo'
import MidlandLogo from './CarrierLogos/Midland/Logo'
import MinimaxLogo from './CarrierLogos/Minimax/Logo'
import MorneauLogo from './CarrierLogos/Morneau/Logo'
import NationexLogo from './CarrierLogos/Nationex/Logo'
import NewPennLogo from './CarrierLogos/NewPenn/Logo'
import NmTransferLogo from './CarrierLogos/NmTransfer/Logo'
import NormandinLogo from './CarrierLogos/Normandin/Logo'
import OldDominionFreightLogo from './CarrierLogos/OldDominionFreight/Logo'
import PolarisLogo from './CarrierLogos/Polaris/Logo'
import PurolatorLogo from './CarrierLogos/Purolator/Logo'
import RcTransportLogo from './CarrierLogos/RcTransport/Logo'
import RciLogo from './CarrierLogos/Rci/Logo'
import ReddawayLogo from './CarrierLogos/Reddaway/Logo'
import RemcoLogo from './CarrierLogos/Remco/Logo'
import RlCarriersLogo from './CarrierLogos/RlCarriers/Logo'
import RDFSLogo from './CarrierLogos/RDFS/Logo'
import RobertLogo from './CarrierLogos/Robert/Logo'
import RonaLogo from './CarrierLogos/Rona/Logo'
import RosenauLogo from './CarrierLogos/Rosenau/Logo'
import SaiaLogo from './CarrierLogos/Saia/Logo'
import SkeletonLogo from './CarrierLogos/Skeleton/Logo'
import SouthEasternFreightLinesLogo from './CarrierLogos/SouthEasternFreightLines/Logo'
import SpeedyLogo from './CarrierLogos/Speedy/Logo'
import SteelesTransferLogo from './CarrierLogos/SteelesTransfer/Logo'
import SynergieCanadaLogo from './CarrierLogos/SynergieCanada/Logo'
import TeamsLogo from './CarrierLogos/Teams/Logo'
import TforceLogo from './CarrierLogos/Tforce/Logo'
import TranskidLogo from './CarrierLogos/Transkid/Logo'
import TransplusLogo from './CarrierLogos/Transplus/Logo'
import TransxLogo from './CarrierLogos/Transx/Logo'
import TstOverlandLogo from './CarrierLogos/TstOverland/Logo'
import UpsLogo from './CarrierLogos/Ups/Logo'
import ValoisLogo from './CarrierLogos/Valois/Logo'
import ViaExpressLogo from './CarrierLogos/ViaExpress/Logo'
import VitranLogo from './CarrierLogos/Vitran/Logo'
import WardLogo from './CarrierLogos/Ward/Logo'
import WesternCanadaExpressLogo from './CarrierLogos/WesternCanadaExpress/Logo'
import WesternExpressLogo from './CarrierLogos/WesternExpress/Logo'
import WwexLogo from './CarrierLogos/WorldWideExpress/Logo'
import YrcFreightLogo from './CarrierLogos/YrcFreight/Logo'
import CTIILogo from './CarrierLogos/CTII/Logo'
import BrooksAndSonsLogo from './CarrierLogos/BrooksAndSons/Logo'
import DYLTLogo from './CarrierLogos/DYLT/Logo'
import MTJGLogo from './CarrierLogos/MTJG/Logo'
import PYLELogo from './CarrierLogos/PYLE/Logo'
import STDFLogo from './CarrierLogos/STDF/Logo'
import AACTLogo from './CarrierLogos/AACT/Logo'
import GroupeRobertLogo from './CarrierLogos/GroupeRobert/Logo'
import PittOhioLogo from './CarrierLogos/PittOhio/Logo'
import TforceFreightCanadaLogo from './CarrierLogos/TforceFreightCanada/Logo'
import BourassaLogo from './CarrierLogos/Bourassa/Logo'
import SeawayExpressLogo from './CarrierLogos/SeawayExpress/Logo'
import HiWay9Logo from './CarrierLogos/HiWay9/Logo'
import ADuiePyleLogo from './CarrierLogos/ADuiePyle/Logo'


import DohrnTransferCompanyLogo from './CarrierLogos/DohrnTransferCompany/Logo'
import MagnumLogisticsLogo from './CarrierLogos/MagnumLogistics/Logo'
import AverittLogo from './CarrierLogos/Averitt/Logo'
import TaxAirLogo from './CarrierLogos/TaxAir/Logo'
import DaytonFreightLogo from './CarrierLogos/DaytonFreight/Logo'



const CarrierLogo: React.FunctionComponent<CarrierLogoProps> = (props) => {
    if (!props.variant) {
        return <SkeletonLogo size={props.size} />
    }

    const Logo = CarrierNameLogoMapping[props.variant] || SkeletonLogo

    return <Logo {...props} />
}

export interface CarrierLogoProps extends React.ComponentProps<typeof BaseCarrierLogo> {
    variant?: string
}

export default CarrierLogo


const CarrierNameLogoMapping: Record<string, React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>>> = {
    Manitoulin: ManitoulinLogo,
    MANI: ManitoulinLogo,
    'GLS Canada': GlsCanadaLogo,
    PRLA: PurolatorLogo,
    CFFO: FastfrateLogo,
    CNPR: CanparLogo,
    DAYR: DayAndRossLogo,
    'Day and Ross': DayAndRossLogo,
    FEDEX: FedexLogo,
    FXFE: FedexLogo,
    FDXG: FedexLogo,
    FDXE: FedexLogo,
    FXFR: FedexLogo,
    FXSP: FedexLogo,
    FXNL: FedexLogo,
    UPS: UpsLogo,
    UPGF: UpsLogo,
    ECHO: EchoLogo,
    SAIA: SaiaLogo,
    RNLO: RlCarriersLogo,
    RLCA: RlCarriersLogo,
    CNWY: ConwayLogo,
    FCSY: FrontlineLogo,
    CTI3: Cti3Logo,
    HMES: HollandLogo,
    'Synergie Canada': SynergieCanadaLogo,
    WWEX: WwexLogo,
    'Canada Post': CanadaPostLogo,
    ABFS: AbfFreightLogo,
    DHL: DhlLogo,
    'Lazr Freight': LazrLogo,
    Estimate: LazrLogo,
    'General Tariff': LazrLogo,
    SteelesTransfer: SteelesTransferLogo,
    Labranche: LabrancheLogo,
    WXSM: WesternExpressLogo,
    NPME: NewPennLogo,
    JPSE: JpsLogistiqueLogo,
    FWDN: ForwardAirLogo,
    FWDA: ForwardAirLogo,
    'Via Express': ViaExpressLogo,
    '18Wheels': EighteenWheelsLogo,
    Rosenau: RosenauLogo,
    RDWY: YrcFreightLogo,
    RC: RcTransportLogo,
    Remco: RemcoLogo,
    TMBM: TeamsLogo,
    Rona: RonaLogo,
    POLT: PolarisLogo,
    GBIT: GuilbaultLogo,
    Valois: ValoisLogo,
    QXTI: TforceFreightCanadaLogo,
    TPBO: BourretLogo,
    SEFL: SouthEasternFreightLinesLogo,
    SZTG: SpeedyLogo,
    AXME: ApexLogo,
    RBTW: ChrLogo,
    'TransX Intermodal': TransxLogo,
    TransX: TransxLogo,
    NMTF: NmTransferLogo,
    RETL: ReddawayLogo,
    Gariepy: GariepyLogo,
    MNME: MinimaxLogo,
    MiniMax: MinimaxLogo,
    JMSM: JaysTransportationLogo,
    Vitran: VitranLogo,
    Midland: MidlandLogo,
    EXLA: EstesLogo,
    'Exprex - Sameday Service': ExprexLogo,
    'Western Canada Express': WesternCanadaExpressLogo,
    Transkid: TranskidLogo,
    ODFL: OldDominionFreightLogo,
    'Transport RCI - EDI': RciLogo,
    ITSL: IntegratedTransportationSolutionLogo,
    NINP: NationexLogo,
    WARD: WardLogo,
    ROB1: RobertLogo,
    RDFS: RDFSLogo,
    OVLD: TstOverlandLogo,
    Transplus: TransplusLogo,
    'Inter-Nord': InterNordLogo,
    Normandin: NormandinLogo,
    CTNP: CascadesLogo,
    AndMore: AndMoreLogo,
    Morneau: MorneauLogo,
    CGVO: CctCanada,
    CTII: CTIILogo,
    'Brooks & Sons': LazrLogo,
    DYLT: DYLTLogo,
    MTJG: MTJGLogo,
    PYLE: PYLELogo,
    STDF: STDFLogo,
    AACT: AACTLogo,
    'Groupe Robert': GroupeRobertLogo,
    PITD: PittOhioLogo,
    TFIN: TforceLogo,
    TAGB: BourassaLogo,
    'SeaWay Express Inc.': SeawayExpressLogo,
    HIWAY9: HiWay9Logo,
    ADUPUS: ADuiePyleLogo,
    DHRN: DohrnTransferCompanyLogo,
    MGUL: MagnumLogisticsLogo,
    AVRT: AverittLogo,
    TAXA: TaxAirLogo,
    DAFG: DaytonFreightLogo,
}
